import {imageService} from "@/services/ImageService";
import {toast} from "vue3-toastify";
import {i18n} from "@/lang";
import {promoService} from "@/services/PromoService";

const state = {
    images: [],
    isAddingImage: false,
    editingImage: false,
    editImageRequest : {
        loading: false
    },
    addImageRequest : {
        loading: false
    },
    getImageRequest : {
        loading: false
    },
    deleteImageRequest: {
        loading: false
    }
};

const mutations = {
    SET_IMAGE(state, images) {
        state.images = [...images];
    },
    setEditingImage(state, editingImage){
        state.editingImage = editingImage;
        state.isAddingImage = false;
    },
    setGetImageRequest(state, {loading, message}){
        state.getImageRequest = {
            loading: loading,
            message: message
        }
    },
    setAddImageRequest(state, {loading, message}){
        state.addImageRequest = {
            loading: loading,
            message: message
        }
    },
    setEditImageRequest(state, {loading, message}){
        state.editImageRequest = {
            loading: loading,
            message: message
        }
    },
    setDeleteImageRequest(state, {loading, message}){
        state.deleteImageRequest = {
            loading: loading,
            message: message
        }
    },
    updateImage(state, newList){
        state.images = newList;
    },
    setIsAddingImage(state, isAddingImage){
        state.isAddingImage = isAddingImage;
        state.editingImage = null;
    }
};

const actions ={
    fetchImages({commit}){
        commit('setGetImageRequest', {loading: true})
        imageService.getAllImages()
            .then((response) => {
                commit('setGetImageRequest', {loading: false})
                commit('updateImage', response.data);
                console.log('dddd',response.data);
            })
            .catch((error) => commit('setGetImageRequest', {loading: false, message: error}))
    },

    deleteImage({commit, state}, imageId){
        imageService.deleteImage(imageId)
            .then(() => {
                toast.success(i18n.global.t("toast.deleteSuccess"));
                const updatedImageList = state.images.filter(element => element.imageId !== imageId);
                commit('updateImage', updatedImageList);
            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.deleteError"));
                commit("setDeleteImageRequest", {loading: false, message: error})
            })
    },

     addImage({ commit, state }, { orgId, extraAttachedFiles }) {
        commit('setAddImageRequest', {loading: true});
        try {
            let successCount = 0;
            let failedCount = 0;
            if (extraAttachedFiles && extraAttachedFiles.length > 0) {
                for (let i = 0; i < extraAttachedFiles.length; i++) {
                    try {
                        const response = imageService.uploadImage(extraAttachedFiles[i], orgId);
                        successCount++;
                    } catch (error) {
                        console.error("Error uploading file:", error);
                        failedCount++;
                    }
                }
            }

            if (successCount > 0) {
                toast.success(`${successCount} images added successfully`);
            }

            if (failedCount > 0) {
                toast.error(`${failedCount} images could not be added`);
            }
            commit('setAddImageRequest', {loading: false});
            commit('setIsAddingImage', false);
        } catch (error) {
            toast.error(i18n.global.t("toast.createError"));
            commit('setAddImageRequest', {loading: false, message: error})
        }
    }
}

export const images = {
    namespaced: true,
    state,
    actions,
    mutations,
}
