import apiClient from '../helpers/ApiClient'
import { handleError, authHeader } from '@/helpers/ApiClient';

export const pickupService = {
    getPickup,
    deletePickup,
    addPickup,
    updatePickup
}

function getPickup() {
    return apiClient.get('/pickup-location', {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

function deletePickup(pickup) {
    return apiClient.delete(`/pickup-location/${pickup.pickupLocationId}`, {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

function addPickup(pickup) {
    return apiClient.post(`/pickup-location`, pickup, {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

function updatePickup(pickup) {
    return apiClient.patch(`/pickup-location/${pickup.pickupLocationId}`, pickup, {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}
