const genericStrings = {
    username: "Nom d'utilisateur",
    password: "Mot de passe",
    org_id: "ID de l'organisation",
    edit: "Modifier",
    save: "Enregistrer",
    cancel: "Annuler",
    create: "Créer",
    search: "Rechercher",
    download: "Télécharger",
    downloadX: "Télécharger X",
    downloadZ: "Télécharger Z",
    from: "De",
    to: "À",
    total: "Total",
    paid: "Payé",
    vat: "TVA",
    user: "Utilisateur",
    comment: "Commentaire",
    price: "Prix",
    accountId: "ID de compte",
    name: "Nom",
    id: "Id",
    actions: "Actions",
    refresh: "Rafraîchir",
    account: "Compte",
    status: "Statut",
    openedAt: "Ouvert à",
    open: "Ouvrir",
    close: "Fermer",
    tableNumber: "Table",
    delete: "Supprimer",
    takeaway: "À emporter",
    deleteConfirm: "Êtes-vous sûr de vouloir supprimer ceci ?<br> Cette action est irréversible !",
    date: "Date",
    filter: "Filtrer",
    ipAddress: "Adresse IP",
    color: "Couleur",
    yes: "Oui",
    no: "Non",
    all: "Tous",
    changePayType: (ctx) => `Changer le type de paiement de ${ctx.named('of')} de ${ctx.named('from')} à ${ctx.named('to')} ? `,
    tables: "Tables",
    delivery: "Livraison",
    shift: "Services",
    openAccounts: "Comptes ouverts",
    lastShift: "Dernier service",
    dailySales: "Ventes quotidiennes",
    rushHours: "Heures de pointe",
    dashboardIntervalChoice: 'Intervalle sélectionné',
    dashboardIntervalCurrentShift: 'Service actuel',
    dashboardIntervalLastShift: 'Dernier service',
    dashboardIntervalToday: 'Aujourd\'hui',
    dashboardIntervalYesterday: 'Hier',
    dashboardIntervalThisWeek: 'Cette semaine',
    dashboardIntervalLastWeek: 'La semaine dernière',
    dashboardIntervalThisMonth: 'Ce mois-ci',
    dashboardIntervalLastMonth: 'Le mois dernier',
    clients: 'Clients',
    mail: 'Mail',
    phone: 'Phone',
    address: 'Adresse',
    street: 'Rue',
    city: 'Ville',
    shiftUser: "Service Travailleur",
    noDataFound: "Aucune donnée trouvée",
    pickup: "Ramassage",
    promo: "Promotion",
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
    sunday: "Dimanche",
    login: "Se connecter",
    product: "Produits",
    productGroup: "Groupes de produits",
    table: "Tables",
    printer: "Imprimantes",
    client: "Clients",
    invoice: "Factures",
    dashboard: "Tableau de bord",
    accounts: "Comptes",
    order: "Commandes",
    stock: "Stocks",
    error: "Erreur",
    bankTransfer: "Virement bancaire",
    offered: "Offert",
    disableCash: "Désactiver l'argent liquide",
    disableCard: "Désactiver la carte",
    kiosk: "Kiosque",
    payment: "Paiement",
    analytics: "Analyses",
    admin: "Administrateur",

};

const navBarStrings = {
    home: "Accueil",
    users: "Utilisateurs",
    products: "Produits",
    groups: "Groupes",
    tables: "Tables",
    my_account: "Mon compte",
    settings: "Paramètres",
    dashboard: "Dashboard",
    analytics: "Analyses",
    account: "Compte",
    acitivity: "Activité",
    order_history: "Commandes",
    account_history: "Comptes",
    shifts: "Services",
    shift: "Service",
    payments: "Paiements",
    log: "Journal",
    logout: "Déconnexion",
    printers: "Imprimantes",
    stock: "Stock",
    image: "Images",
    kitchen: "Message",
    clients: "Clients",
    invoice: "Factures",
    promo: "Promo"
};

const toastStrings = {
    successSave: "Enregistrement réussi",
    errorSave: "Erreur d'enregistrement",
    successEdit: "Modification réussie",
    errorEdit: "Erreur de modification",
    createSuccess: "Création réussie",
    createError: "Erreur de création",
    deleteSuccess: "Suppression réussie",
    deleteError: "Erreur de suppression",
    duplicateSuccess: "Duplication réussie",
    duplicateError: "Erreur de duplication",
};

const loginPageStrings = {
    welcome_back: "Bon retour",
    login_page_subtitle: "Votre compagnon de restaurant parfait toujours là quand vous en avez besoin",
    login_page_form_title: "Connectez-vous",
    login_page_form_subtitle: "Entrez vos identifiants",
    login_button: "Connexion"
};

const dashboardStrings = {
    yoursInfos: "Votre établissement",
    empty: "Aucune table n'est occupée",
    shiftTitle: "Table ouverte",
    openTableText: "Table ouverte",
    takeawayText: "À emporter ouvert",
    noshiftOpen: "Aucun service ouvert",
    takeawayTitle: "À emporter",
    currentShift: "Service en cours",
    latestShift: "Dernier service",
    lastShift: "Dernier service",
    accountsTotal: "Total des comptes",
    accountsTooltip: "Nombre de comptes ouverts appartenant aux shifts sélectionnés (Ou au moins 1 article du compte a été payé)",
    remainingUnpaid: "Reste à payer",
    remainingUnpaidTooltip: "Montant total restant à payer pour les comptes ouverts appartenant ou non aux shifts sélectionnés",
    salesPaid: "Ventes payées",
    salesPaidTooltip: "Montant total payé pour les comptes ouverts et fermés appartenant aux shifts sélectionnés",
    averageBasket: "Panier moyen",
};

const userPageStrings = {
    username: "Nom d'utilisateur",
    password: "Mot de passe",
    type: "Type d'utilisateur",
    pincode: "Code PIN de la caisse",
    vwTerminalIp: "IP du terminal VW",
    vwTerminalId: "ID du Terminal VW (cloud)",
    userPrinter: "Imprimante de l'utilisateur",
    selectAbilities: "Sélectionner les capacités",
    appMode: "Mode d'affichage",
    pincodeTooltip: "Le code PIN de la caisse est utilisé pour deverrouiller la caisse enregistreuse lorsque le verrouillage par code PIN est activé. Il sera aussi utilisé enregistrer les actions de l'utilisateur sur la caisse principale",
    userPrinterTooltip: "L'imprimante qui sera utilisée pour cet utilisateur. Cela remplacera l'imprimante principale si elle est définie et permettra d'imprimer les tickets de TVA/récapitulatifs sur cette imprimante",
};

const imagePageStrings = {
    name: "Images",
    addImage: "Ajouter une Image",
    textEnter: "Entrer l'url :",
    textUpload: "Glissez-déposez vos images ici ou cliquez pour ajouter",
    limit: "Le fichier dépasse la taille limite de 2MB"
}

const productGroupPageStrings = {
    productGroups: "Groupes de produits",
    addGroup: "Ajouter un groupe",
    vatIn: "TVA entrée",
    vatOut: "TVA sortie",
    printerIp: "Adresse IP de l'imprimante",
    name: "Nom",
    userHidden: "Caché?",
    webHidden: "Caché sur site web?",
    isHidden: "Caché dans l'application",
    isNotHidden: "Visible dans l'application",
    KitchenPrintEnabled: "Imprimer?",
    noFundResultTable: "Aucun client trouvé.",
    loadingResultTable: "Chargement des données clients. Veuillez patienter.",
    color: "Couleurs",
    vatTooltip: "Une TVA qui sera suggérée lorsque ce groupe sera attribué à un produit. Changer ceci ne modifiera pas les TVA des produits de ce groupe",
    orderToolltip: "Ordre du groupe dans la liste, cela sera utilisé pour trier les groupes par ordre croissant",
    kitchenPrintEnabledTooltip: "Activer/Désactiver l'impression des commandes sur l'imprimante assignée pour les produits de ce groupe",
    userHiddenTooltip: "Masquer ce groupe de l'interface utilisateur empêchant les commandes directes des produits de ce groupe, mais permettre toujours son utilisation pour les options payantes/gratuites des produits",
    maxChoicePaid: "Max payant sous-groupe",
    maxChoiceFree: "Max gratuit sous-groupe",
    maxChoicePaidToolTip: "En attachant ce groupe à un produit, vous pouvez restreindre le nombre de sous-produits payants en utilisant cette option.",
    maxChoiceFreeToolTip: "En attachant ce groupe à un produit, vous pouvez restreindre le nombre de sous-produits gratuits en utilisant cette option."
};

const kitchenPageStrings = {
    name: "Message cuisine",
    addMessage: "Ajouter un message",
    textEnter: "Message : ",
    erText: 'Message requis',
    noFundResultTable: "Aucune message trouvé.",
    loadingResultTable: "Chargement...",
}

const productPageStrings = {
    name: "Nom du produit",
    products: "Produits",
    addProduct: "Ajouter un produit",
    priceVatInclIn: "Prix d'entrée",
    priceVatInclOut: "Prix de sortie",
    vatIn: "TVA entrée",
    vatOut: "TVA sortie",
    codeBarre: "Code-barres",
    description: "Description",
    stock: "Stock",
    productGroup: "Groupe de produits",
    productGroupParent: "Groupe Parent",
    productGroupOptions: "Options gratuites du groupe de produits",
    productGroupOptionsPaid: "Options payantes du groupe de produits",
    productFilterByCategory: "Filtrer par catégorie",
    timedproduct: "Produit chronométré?",
    allowDecimal: "Autoriser les unités décimales?",
    dynamicPrice: "Prix dynamique?",
    multiChoice: "Choix multiple autorisé?",
    orderIndex: "Ordre",
    weightPrice: "Prix au poids?",
    visibility: "Visibilité APP",
    visibilityWeb: "Web Visibilité",
    imageUrl: "URL de l'image en https",
    color: "Couleur",
    userHiddenTooltip: "Masquer ce produit de l'interface utilisateur empêchant les commandes directes de ce produit, mais permettre toujours son utilisation pour les options payantes/gratuites des produits",
    orderTooltip: "Ordre du produit dans la liste, cela sera utilisé pour trier les produits du même groupe par ordre croissant",
    timedProductTooltip: "Lorsqu'il est activé, ce produit démarrera un chronomètre lors de la commande et à la fermeture, utilisera le prix du produit comme prix/minute pour calculer le prix total",
    allowDecimalTooltip: "Permettra à l'utilisateur d'entrer une valeur décimale pour la quantité du produit en appuyant longuement avant de commander ou avant d'envoyer en sélectionnant produit > unités",
    dynamicPriceTooltip: "Cela ignorera le prix ci-dessus et obligera l'utilisateur à entrer un prix lorsque ce produit est sélectionné",
    weightPriceTooltip: "Cela obligera l'utilisateur à entrer un poids en KG lorsque ce produit est sélectionné. Il détectera automatiquement le poids si une balance est connectée",
    promo_percent: "Promotion fixe en %",
    webHiddenTooltip: "Masquer ce groupe de l'interface web empêchant les commandes directes des produits de ce groupe, mais permettre toujours son utilisation pour les options payantes/gratuites des produits",
    favorite: "Favori",
    tags: "Tags",
    tagsTooltip: "Saisissez vos tags, qui serviront à filtrer vos produits sur votre site web. Veillez à séparer chaque tag par une virgule.",
    enterBarcode: "Entrer un code-barre"
};

const stockPageStrings = {
    name: "Nom du produit",
    stock: "Stock",
    productGroup: "Groupe de produits",
    productGroupOptions: "Options gratuites du groupe de produits",
    productGroupOptionsPaid: "Options payantes du groupe de produits",
    productFilterByCategory: "Filtrer par catégorie",
    timedproduct: "Produit chronométré?",
    allowDecimal: "Autoriser les unités décimales?"
};

const tableStrings = {
    name: "Tables",
    addTable: "Ajouter une table",
    nameZones: "Zones",
    selectZone: "Sélectionner des zones",
    addZones: "Ajouter des zones",
    nameTypes: "Types",
    selectType: "Sélectionner des types",
    addTypes: "Ajouter des types",
    tableNumber: "Numéro de table",
    zoneNumber: "Numéro de zone",
    zoneName: "Nom de la zone",
    typeName: "Nom du type"
};

const paymentsStrings = {
    header: "Transactions de paiement",
    orderId: "ID de commande",
    paymentType: "Type",
    amount: "Montant",
    returned: "Rendu",
    totalCash: "Total espèces",
    totalCard: "Total carte",
    totalOffered: "Total offert",
    totalError: "Total erreur",
    totalVatExcl: "Total HT",
    totalVatIncl: "Total",
    vatValue: "Valeur",
    totalBankTransfer: "Total virement"
};

const orderedProductsString = {
    header: "Historique des commandes",
    accountId: "ID de compte",
    linkedTo: "Lié à"
};

const accountHistory = {
    header: "Historique des comptes"
};

const shiftDetail = {
    header: "Détail du service"
};

const shiftStat = {
    header: "Statistiques du service"
};

const shift = {
    openShift: "Heure d'ouverture",
    openUserName: "Utilisateur d'ouverture",
    openUserTime: "Heure d'ouverture",
    openUserCash: "Caisse de départ",
    closeUserName: "Utilisateur de fermeture",
    closeUserTime: "Heure de fermeture",
    closeUserCash: "Caisse de fin",
    totalCash: "Espèces",
    totalCard: "Carte",
    totalOffered: "Offert",
    totalBankTransfer: "Virement bancaire",
    total: "Total du service",
    inProgress: "En cours...",
    endComment: "Commentaire sur la fin du service",
    paymentType: "Forcer la fermeture du compte avec un paiement mixte par",
    totalCashClose: "Votre montant total en espèces",
    HeaderShiftClose: "Fermer ce service",
    MessageCloseShift: "Êtes-vous sûr de vouloir terminer le service ? Vous ne pourrez plus rouvrir ce service !",
    MessageOpenShift: "Êtes-vous sûr de vouloir ouvrir un nouveau le service ?",
    OpenNewShift: "Ouvrir un nouveau service?",
    OpenNewShiftButton: "Ouvrir un service",
};

const printerStrings = {
    printers: "Imprimantes",
    addPrinter: "Ajouter une imprimante",
    printerName: "Nom de l'imprimante",
    doublePrint: "Imprimer en double"
};

const settingsPageStrings = {
    name: "Votre organisation",
    organisationId: "Code de l'organisation",
    organisationName: "Nom",
    vatNumber: "Numéro de TVA",
    address: "Adresse",
    telephone: "Téléphone",
    contactEmail: "Email de contact",
    website: "Site web",
    mainPrinterIpAddress: "Adresse IP de l'imprimante principale",
    takeawaySectionEnabled: "Section à emporter activée",
    tableSectionEnabled: "Section des tables activée",
    shiftEnabled: "Service activé",
    printerSettings: "Paramètres de l'imprimante",
    vivaWallet: "Viva Wallet",
    webSettings: "Paramètre de commande online",
    superSettings: "Super paramètres",
    kitchenTopSpacing: "Espacement en haut de la cuisine",
    kitchenGroupItems: "Regrouper les éléments de la cuisine",
    charactersPerLine: "Caractères par ligne",
    bigKitchenFontSize: "Police d'écriture large pour cuisine",
    pinLockTimeout: "Délai de verrouillage par code PIN (secondes)",
    pinLockEnabled: "Verrouillage par code PIN",
    kitchenPrintEnabled: "Imprimer le ticket de commande",
    autoCloseAccountOnPaymentEnabled: "Fermer le compte après paiement",
    autoPrintSummaryTicketEnabled: "Imprimer automatiquement le ticket après paiement",
    autoPrintShiftXY: "Imprimer automatiquement X/Z",
    hideShiftInfo: "Masquer les informations du service",
    paymentTypeOfferedEnabled: "Autoriser l'offre",
    paymentTypeTransferEnabled: "Autoriser le virement bancaire",
    sendOrderBehavior: "Action après envoi",
    autoOpenCashRegister: "Ouvrir automatiquement la caisse enregistreuse",
    DEFAULT: "PAR DÉFAUT",
    NAV_BACK: "RETOUR",
    NAV_PAYMENT: "ALLER AU PAIEMENT",
    orgaFlag: "Paramètres de l'application",
    refundEnabled: "Autoriser le remboursement",
    addItemsToTop: "Nouveau produit en haut",
    autoOpenNewAccount: "Ouvrir automatiquement un nouveau compte",
    normalAccountOpenButton: "Bouton '+' pour ouvrir un compte",
    vwIsvAmount: "Montant ISV",
    vwEnabled: "VW activé",
    vwTerminalMerchantId: "ID de marchand ISV",
    vwTerminalId: "ID de terminal (pour les terminaux NEXT)",
    mapsAutoCompleteEnabled: "Intégration Google Maps",
    autoAssignDeviceToMainType: "Affecter automatiquement l'appareil au type principal",
    workerShiftsEnabled: "Activer les shift utilisateurs (Pointeuse)",
    pinLockTooltip: "Exiger un code PIN pour accéder à l'application",
    shiftsTooltip: "Activer le système de service, cela obligera l'utilisateur à ouvrir un service avant d'utiliser l'application et à le fermer à la fin de chaque service",
    printOrderTicketTooltip: "Activer l'impression d'un ticket lors de l'envoi d'une commande à la cuisine",
    closeAccountAfterPaymentTooltip: "Fermer automatiquement le compte après un paiement",
    autoPrintSummaryTicketTooltip: "Imprimer automatiquement un ticket récapitulatif après un paiement",
    allowOfferTooltip: "Activer le bouton d'offre sur l'écran de paiement pour TOUS les utilisateurs",
    actionAfterSendTooltip: `Choisissez ce qui se passe après l'envoi d'une commande à la cuisine : 
 - PAR DÉFAUT: Rester sur l'écran de commande 
 - RETOUR: Retourner à l'écran de commande 
 - ALLER AU PAIEMENT: Aller à l'écran de paiement`,
    openCashRegisterTooltip: "Ouvrir automatiquement la caisse enregistreuse lorsqu'un paiement en espèces est effectué après la confirmation du paiement",
    autoPrintShiftXZTooltip: "Imprimer automatiquement le X/Z sur l'imprimante lorsque le service est fermé dans l'application",
    hideShiftInfoTooltip: "Masquer les informations du service telles que le type de paiement et le montant total du service sur la page de gestion des services dans l'application",
    allowBankTransferTooltip: "Activer le mode de paiement par virement bancaire sur l'écran de paiement pour TOUS les utilisateurs",
    allowRefundTooltip: "Activer le bouton de remboursement dans un compte à partir de l'historique pour TOUS les utilisateurs",
    newProductOnTopTooltip: "Lorsqu'il est activé, un produit ajouté à un compte/table apparaîtra en haut de la liste au lieu du bas",
    autoOpenNewAccountTooltip: "Ouvrir automatiquement un nouveau compte lorsque le dernier est fermé. Cela permettra de prendre des commandes directement après la fermeture du compte précédent.",
    separateInDeliveryTakeawayTooltip: `Desactivé : cela fournira 2 types de boutons d'ouverture de compte sur l'écran d'accueil, livraison/à emporter et table.
     Activé : Cela fournira seulement un bouton '+' pour ouvrir un nouveau compte`,
    subSectionPayment: "Paiement",
    subSectionHome: "Écran d'accueil",
    subSectionShift: "Service",
    subSectionOrdering: "Commande",
    subSectionWebsite: "Website",
    mainPrinterTooltip: "L'adresse IP de l'imprimante principale qui sera utilisée pour imprimer les tickets de TVA/récapitulatifs par defaut",
    pinLockTimeoutTooltip: "Le délai en secondes avant que le verrouillage par code PIN ne soit activé. Cela ne fonctionnera que si le verrouillage par code PIN est activé",
    onlineOrderWebPageEnabled: "Commande online active",
    onlineOrderOpen: "Commande online ouverte",
    onlineOrderDeliveryEnabled: "Livraison disponible",
    payOnlineEnabled: "Paiement online disponible",
    payAtPickupEnabled: "Paiement après commande disponible",
    onlineOrderHideNoStockProducts: "Uniquement les produits avec stock disponible",
    logoImageUrl: "Lien image de votre logo",
    ticketLogoImageUrl: "Lien image du logo à afficher sur les tickets",
    bannerImageUrl: "Lien image de votre bannière",
    hideProductImagesInApp: "Cacher les images sur l'app",
    hideProductImagesInAppTooltip: "Si vous avez placer des images sur vos produits, cela permet de ne pas les afficher sur l'application posbel, mais de garder les images visibles pour votre QR-CODE/KIOSK/...",
    webOrgaName: "Nom de l'organisation pour le site web",
    autoPrintSummaryTicketOnSend: "Impression auto du proforma ticket",
    autoPrintVatTicketOnSend: "Impression auto du ticket TVA",
    autoPrintSummaryTicketOnSendTooltip: "Permettre d'imprimer automatiquement le ticket proforma après l'envoi d'une commande",
    autoPrintVatTicketOnSendTooltip: "Permettre d'imprimer automatiquement le ticket TVA après l'envoi d'une commande",
    subscriptionEnd: "Fin d'abonnement",
    onlineOrderMinAmount: "Montant minimum pour commande",
    accountBankName: "Compte en banque"
};

const clientStrings = {
    addClient: "Ajouter un client",
    name : "Nom",
    phone: "Téléphone",
    street: "Rue",
    city: "Ville",
    zipCode: "Code Postal",
    number: "Numéro",
    box: "Boite aux lettres",
    business: "Professionnel",
    vat: "TVA",
    email: "Email",
    extra: "Info Supplémentaire",
    erName: "Nom requis",
    erPhone: "Numéro requis",
    erStreet: "Rue requise",
    erCity: "Ville requise",
    erZipCode: "Code postal requis",
    erNumber: "Numéro requis",
    erPhone2: "Numéro invalide",
    erBox: "Boîte aux lettres requise",
    erMail: "Email requis",
    erMail2: "Email invalide",
    erBusiness: "Sélection requise",
    erVat: "TVA requis",
    erVat2: "TVA invalide"
}


const invoicePageStrings = {
    markAsPaid: 'Mark as paid',
    Paid: 'Paid',
    markPaidQuestion: 'Voulez-vous marquer cette facture comme payée?',
};

const promoPageStrings = {
    addPromo: 'Ajouter une promo',
    promoCode: 'Code promo',
    discountAmount: 'Montant ristourne',
    startDate: 'Valide du (YYYY-MM-JJ)',
    endDate: 'au (YYYY-MM-JJ)',
    maxUses: 'Usage maximal',
    usedCount: 'Utilisation actuel',
    erPromoCode: 'Promo code non valide',
    erPromoCode2: 'Promo code doit contenir que des lettres et chiffres',
    erMaxUses: 'Champ requis',
    erDiscountAmount: 'Montant supérieur à 0',
    erUsedCount: 'Champ requis',
    erStartDate: 'Champ requis',
    erEndDate: 'Champ requis',
    erInvalidDateRange: "La date de début doit être avant la date de fin"
};

const pickupPageStrings = {
    addPickup: 'Ajouter un pickup',
    notFound: 'Aucun pickup trouvé.',
    name: 'Nom',
    address: "Adresse",
    phone: "Téléphone",
    openingHours: "Commande",
    pickupTime: "Ramassage",
    hoursField: "Créneaux horaire",
    day: "Jour",
    type: "Type",
    hour: "Début",
    hour2: "Fin",
    add: "Ajouter",
    erName: "Nom requis",
    erPhone: "Numéro requis",
    erPhone2: "Numéro invalide",
    erAddress: "Adresse requise",
    erSlot: "Veuillez ajouter un créneau"
};

export default {
    generic: genericStrings,
    navBar: navBarStrings,
    toast: toastStrings,
    dashboard: dashboardStrings,
    loginPage: loginPageStrings,
    userPage: userPageStrings,
    productGroupPage: productGroupPageStrings,
    productPage: productPageStrings,
    stockPage: stockPageStrings,
    tablePage: tableStrings,
    paymentsPage: paymentsStrings,
    orderedProductsPage: orderedProductsString,
    accountHistoryPage: accountHistory,
    shiftDetailPage: shiftDetail,
    shiftStatPage: shiftStat,
    clientStrings: clientStrings,
    shiftPage: shift,
    pickupPageStrings:pickupPageStrings,
    printerStrings: printerStrings,
    settingsPage: settingsPageStrings,
    invoicePage: invoicePageStrings,
    kitchenPageStrings: kitchenPageStrings,
    imagePageStrings: imagePageStrings,
    promoPageStrings: promoPageStrings,
};
