<template>
  <div>
    <popup-view :isVisible="productGroupState.editingGroup || productGroupState.isAddingGroup"
                @close="setEditingGroup(null); setIsAddingGroup(false)"
    >
      <edit-product-group-dialog/>
    </popup-view>
    <popup-view :isVisible="deleteOpenDialog" @close="deleteOpenDialog = false">
      <confirm-delete-dialog :product="currentSelect" @submitDeleteAction="submitDelete"
                             @cancelDeleteAction="deleteOpenDialog = false"/>
    </popup-view>
    <page-header
        class="header"
        @actionClick="setIsAddingGroup(true)"
        iconName="package.svg"
        actionIconName="plus.svg"
        :actionText="$t('productGroupPage.addGroup')"
        :headerTitle="$t('productGroupPage.productGroups')"
    />

    <DataTable
        :value="productGroupState.productGroups"
        v-model:filters="filters"
        filter-display="row"
        table-style="min-width: 50rem"
        :loading="loading"
        :global-filter-fields="['name', 'printer.name']"
        editMode="cell"
        @cell-edit-complete="onCellEditComplete"
    >
      <template #header>
        <div class="flex justify-content-end" style="margin-top: 15px">
            <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Keyword Search"/>
            </span>
        </div>
      </template>
      <template #empty> {{ $t('productGroupPage.noFundResultTable') }}</template>
      <template #loading> {{ $t('productGroupPage.loadingResultTable') }}</template>
      <!-- <Column field="productGroupId" header="Code" sortable></Column> -->
      <Column field="name" header="Name" style="min-width: 15rem">
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" autofocus/>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter"
                     placeholder="Search by name"/>
        </template>
      </Column>
      <Column field="vatIn" header="In" sortable></Column>
      <Column field="vatOut" header="Out" sortable></Column>
      <Column field="printers" header="Printers">
        <template #body="{ data }">
          <div v-for="(item, index) in data.printers"
               :key="item.id"
               class="printer-item"
               :class="{ 'last-item': index === data.printers.length - 1 }">
            {{ item.name }}
          </div>
        </template>
      </Column>
      <Column field="color" header="Color" sortable>
        <template #body="{ data }">
          <span class="bread-crumb-base" :style="{ backgroundColor: data.color }" v-if="data.color">{{ data.color }}</span>
        </template>
      </Column>
        <Column field="imageUrl" header="Image" sortable>
            <template #body="{ data }">
            <span v-if="data?.imageUrl">
              <a :href="`${data.imageUrl}`" target="_blank">
                <img :src="`${data.imageUrl}`" width="45px"
                  class="w-6rem border-round"
                  @error="hideImage"
                  style="width: 50px; height: 50px;"/>
              </a>
            </span>
            </template>
        </Column>

      <Column field="userHidden" :header="$t('productPage.visibility')" sortable>
        <template #body="{ data }">
          <button class="bread-crumb-base" @click="changeSpecificField({ productGroup: data, field: 'userHidden' })">
            <inline-svg
                v-if="data.userHidden"
                class="row-icon disabled"
                :src="require('@/assets/icons/eye-off.svg')"
            />
            <inline-svg
                v-else
                class="row-icon"
                :src="require('@/assets/icons/eye.svg')"
            />
          </button>
        </template>
      </Column>
      <Column field="webHidden" :header="$t('productPage.visibilityWeb')" sortable v-if="user?.organization?.onlineOrderOpen">
        <template #body="{ data }">
          <button class="bread-crumb-base" @click="changeSpecificField({ productGroup: data, field: 'webHidden' })">
            <inline-svg
                v-if="data.webHidden"
                class="row-icon disabled"
                :src="require('@/assets/icons/eye-off.svg')"
            />
            <inline-svg
                v-else
                class="row-icon"
                :src="require('@/assets/icons/eye.svg')"
            />
          </button>
        </template>
      </Column>

      <Column field="kitchenPrintEnabled" :header="$t('productGroupPage.KitchenPrintEnabled')" sortable>
        <template #body="{ data }">
          <button class="bread-crumb-base"
                  @click="changeSpecificField({ productGroup: data, field: 'kitchenPrintEnabled' })">
            <inline-svg
                v-if="data.kitchenPrintEnabled"
                class="row-icon"
                :src="require('@/assets/icons/print-enabled.svg')"
            />
            <inline-svg
                v-else
                class="row-icon disabled"
                :src="require('@/assets/icons/print-disabled.svg')"
            />
          </button>
        </template>
      </Column>

      <Column field="orderIndex" header="Order" sortable>
        <template #editor="{ data, field }">
            <InputText v-model="data[field]" autofocus style="width: 60px"/>
        </template>
      </Column>

        <Column field="optionOrderIndex" header="Option Order" sortable>
            <template #editor="{ data, field }">
                <InputText v-model="data[field]" autofocus style="width: 60px"/>
            </template>
        </Column>

      <Column field="quantity" header="Actions">
        <template #body="{data}">
          <div class="edit-row-btn">
            <button @click="setEditingGroup(data)">
              <inline-svg :src="require('@/assets/icons/edit.svg')"/>
            </button>
            <button class="delete" @click="openDeleteDialog(data)">
              <inline-svg :src="require('@/assets/icons/trash.svg')"/>
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import {ref} from 'vue';
import {mapActions, mapState, mapMutations} from "vuex";
import {FilterMatchMode} from 'primevue/api';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import PageHeader from './components/PageHeader.vue';
import PopupView from './components/PopupView.vue';
import EditProductGroupDialog from './dialogs/EditProductGroupDialog.vue';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog.vue';
import {isNumber} from "chart.js/helpers";

export default {
  name: "ProductGroupsPage",
  components: {
    DataTable,
    Column,
    InputText,
    PageHeader,
    PopupView,
    EditProductGroupDialog,
    ConfirmDeleteDialog
  },
  data() {
    return {
      filters: ref({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        name: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        printer: {value: null, matchMode: FilterMatchMode.STARTS_WITH}
      }),
      deleteOpenDialog: false,
      currentSelect: {}
    }
  },
  computed: {
    ...mapState({
      productGroupState: 'productGroup'
    }),
    ...mapState("account", ["user"]),
  },
  methods: {
    ...mapActions('productGroup', ['getProductGroups', 'deleteProductGroup', 'changeSpecificField']),
    ...mapMutations('productGroup', ['setIsAddingGroup', 'setEditingGroup']),
    openDeleteDialog(item) {
      this.deleteOpenDialog = true;
      this.currentSelect = item;
    },
    hideImage(event) {
      event.target.style.display = 'none';
    },
    submitDelete() {
      this.deleteProductGroup(this.currentSelect);
      this.deleteOpenDialog = false;
    },
    onCellEditComplete(event) {
      const { data: productGroup, newValue: updatedValue, field: columnField } = event;
      const isValueChanged = updatedValue.toString() !== productGroup[columnField].toString();

      const updateCellData = (field) => {
        productGroup[field] = updatedValue;
        this.changeSpecificField({ productGroup, field });
      }
      switch (columnField) {
        case 'orderIndex':
          if (isNumber(updatedValue)) {
            if (isValueChanged) updateCellData('orderIndex');
          } else {
            event.preventDefault();
          }
          break;
        case 'name':
          if (isValueChanged) updateCellData('name');
          else event.preventDefault();
          break;
        default:
          event.preventDefault();
          break;
      }
    }
  },
  mounted() {
    this.getProductGroups();
  }
}
</script>

<style lang="scss" scoped>
.printer-item {
  background-color: rgba(129, 137, 201, 0.18);
  padding: 8px;
  border-radius: 8px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  justify-content: center;
}

.last-item {
  margin-bottom: 0;
}

.edit-row-btn {
  column-gap: 10px;
  background: none !important;
}

.row-icon {
  width: 18px;
  height: 18px;

  &.disabled {
    color: $color-secondary-text !important;
  }
}
</style>