<template>
    <div class="main">
        <div class="form-input-container" v-if="user.orgId === currentProduct.orgId || !currentProduct.orgId">
            <DesignInput
                v-model:customValue="name"
                :error="hasEmptyValue(name)"
                :hint="$t('productPage.name')"
            />
            <design-select
                :error="hasEmptyValue(selectedProductGroupId)"
                :hint="$t('productPage.productGroup')"
                v-model:customValue="selectedProductGroupId"
            >
                <option
                    v-for="item in productGroups"
                    :key="item.productGroupId"
                    :value="item.productGroupId"
                >
                    {{ item.name }}
                </option>
            </design-select>

            <div class="row">
                <div class="col-6">
                    <div class="mr-1">
                        <DesignInput
                            v-model:customValue="priceVatInclIn"
                            type="number"
                            :hint="$t('productPage.priceVatInclIn')"
                        />
                    </div>
                </div>
                <div class="col-6">
                    <div class="ml-1">
                        <design-select
                            :error="hasEmptyValue(selectedTvaInGroup)"
                            :hint="$t('productPage.vatIn')"
                            v-model:customValue="selectedTvaInGroup"
                        >
                            <option
                                v-for="item in productTvaGroup"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </design-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="mr-1">
                        <DesignInput
                            v-model:customValue="priceVatInclOut"
                            type="number"
                            :hint="$t('productPage.priceVatInclOut')"
                        />
                    </div>
                </div>
                <div class="col-6">
                    <div class="ml-1">
                        <design-select
                            :error="hasEmptyValue(selectedTvaOutGroup)"
                            :hint="$t('productPage.vatOut')"
                            v-model:customValue="selectedTvaOutGroup"
                        >
                            <option
                                v-for="item in productTvaGroup"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </design-select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="mr-1">
                        <DesignInput
                            v-model:customValue="stock"
                            type="number"
                            :hint="$t('productPage.stock')"
                        />
                    </div>
                </div>
                <div class="col-6">
                    <div class="ml-1">
                        <DesignInput
                            v-model:customValue="orderIndex"
                            type="number"
                            :hint="$t('productPage.orderIndex')"
                            :tooltip-text="$t('productPage.orderTooltip')"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="mr-1">
                        <design-select
                            :fullSize="true"
                            :hint="$t('productPage.timedproduct')"
                            v-model:customValue="timedProduct"
                            :tooltip-text="$t('productPage.timedProductTooltip')"
                        >
                            <option :value="true">{{ $t("generic.yes") }}</option>
                            <option :value="false">{{ $t("generic.no") }}</option>
                        </design-select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="ml-1">
                        <design-select
                            :fullSize="true"
                            :hint="$t('productPage.allowDecimal')"
                            v-model:customValue="allowDecimalUnits"
                            :tooltip-text="$t('productPage.allowDecimalTooltip')"
                        >
                            <option :value="true">{{ $t("generic.yes") }}</option>
                            <option :value="false">{{ $t("generic.no") }}</option>
                        </design-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="mr-1">
                        <design-select
                            :fullSize="true"
                            :hint="$t('productPage.dynamicPrice')"
                            v-model:customValue="dynamicPrice"
                            :tooltip-text="$t('productPage.dynamicPriceTooltip')"
                        >
                            <option :value="true">{{ $t("generic.yes") }}</option>
                            <option :value="false">{{ $t("generic.no") }}</option>
                        </design-select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="ml-1">
                        <design-select
                            :fullSize="true"
                            :hint="$t('productPage.weightPrice')"
                            v-model:customValue="weightPrice"
                            :tooltip-text="$t('productPage.weightPriceTooltip')"
                        >
                            <option :value="true">{{ $t("generic.yes") }}</option>
                            <option :value="false">{{ $t("generic.no") }}</option>
                        </design-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="mr-1">
                        <div class="input-container">
                            <design-select
                                :fullSize="true"
                                :hint="$t('productGroupPage.userHidden')"
                                v-model:customValue="userHidden"
                                :tooltip-text="$t('productPage.userHiddenTooltip')">
                                <option :value="true">{{ $t("generic.yes") }}</option>
                                <option :value="false">{{ $t("generic.no") }}</option>
                            </design-select>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="ml-1">
                        <div class="input-container">
                            <design-select
                                :fullSize="true"
                                :hint="$t('productGroupPage.webHidden')"
                                v-model:customValue="webHidden"
                                :tooltip-text="$t('productPage.webHiddenTooltip')">
                                <option :value="true">{{ $t("generic.yes") }}</option>
                                <option :value="false">{{ $t("generic.no") }}</option>
                            </design-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 input-container">
                <DesignInput
                    v-model:customValue="promoPercent"
                    type="number"
                    :hint="$t('productPage.promo_percent')"
                />
            </div>

            <div class="col-12">
                <div class="mt-2 form-input-container">
                    <design-input-color-list :hint="$t('generic.color')" :custom-value="productColor"
                                             v-model:custom-value="productColor"/>
                </div>
            </div>

            <div class="mt-2">
                <div class="description-zone ">
                  <div class="description-content">
                    <div v-if="currentLanguage === 'FR'">
                      <DesignInputArea
                          class="text-area-description"
                          v-model:customValue="descriptionFR"
                          :hint="$t('productPage.description')"
                      />
                    </div>
                    <div v-if="currentLanguage === 'NL'">
                      <DesignInputArea
                          class="text-area-description"
                          v-model:customValue="descriptionNL"
                          :hint="$t('productPage.description')"
                      />
                    </div>
                    <div v-if="currentLanguage === 'EN'">
                      <DesignInputArea
                          class="text-area-description"
                          v-model:customValue="descriptionEN"
                          :hint="$t('productPage.description')"
                      />
                    </div>
                  </div>
                  <div class="btn-language-zone ">
                    <button
                        class="bread-crumb-base m-1"
                        @click="currentLanguage = 'FR'"
                        :class="{ 'active-language': currentLanguage === 'FR' }">
                        FR
                    </button>
                    <button
                        class="bread-crumb-base m-1"
                        @click="currentLanguage = 'NL'"
                        :class="{ 'active-language': currentLanguage === 'NL' }">
                        NL
                    </button>
                    <button
                        class="bread-crumb-base m-1"
                        style="margin-bottom: 0 !important"
                        @click="currentLanguage = 'EN'"
                        :class="{ 'active-language': currentLanguage === 'EN' }">
                        EN
                    </button>
                </div>
                </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="input-row" id="barcodeInput" style="align-items: flex-end !important">
                  <DesignInput v-model:custom-value="barcodeToAdd" type="text" :hint="$t('productPage.enterBarcode')" />
                  <button class="addBtn" @click="addBarcode">
                    <inline-svg :src="require('@/assets/icons/plus.svg')" style="width: 18px; height: 18px;" />
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p class="input-hint">{{$t('productPage.codeBarre')}}</p>
                <div class="slot-box">
                  <ul v-if="barcodeList.length">
                    <li v-for="(item, index) in barcodeList.split(',')" :key="'barcode' + index" class="slot-item mb-2">
                      <span>{{ item }}</span>
                      <button class="delete"  @click="removeBarcode(index)">
                        <inline-svg :src="require('@/assets/icons/trash.svg')" style="width: 18px; height: 18px;" />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Image URL -->
            <div class="row">
              <!-- Champ principal pour l'image -->
              <DesignInput
                  v-model:customValue="imageUrl"
                  v-model:attachedFile="attachedFile"
                  :hint="$t('productPage.imageUrl')"
                  :file-attach-enabled="true"
                  @click="toggleDropdown('imageDropdown1')" />

              <Dropdown
                  ref="imageDropdown1"
                  v-model="imageUrl"
                  :options="images"
                  optionLabel="imageUrl"
                  optionValue="imageUrl"
                  style="width: 0% !important;"
                  class="org-dropdown"
                  filter
                  filterPlaceholder="Search"
              >

                <template #option="slotProps">
                  <div class="dropdown-item">
                    <img :src="slotProps.option.imageUrl" alt="Preview" class="dropdown-image"/>
                    <span class="dropdown-text">{{ slotProps.option.imageUrl.replace("https://posbel-images-upload.s3.amazonaws.com/", "") }}</span>
                  </div>
                </template>
              </Dropdown>
            </div>
            <div class="row">
              <DesignInput
                  v-model:customValue="extraImageUrls"
                  v-model:attachedFile="extraAttachedFiles"
                  :tooltip-text="$t('productPage.extraImageUrlsTooltip')"
                  :hint="$t('productPage.extraImageUrls')"
                  :file-attach-enabled="true"
                  :multiple="true"
              />
            </div>
            <div class="row">
                <Dropdown
                    ref="parentProductDropdown"
                    v-model="parentProductId"
                    :options="products"
                    optionLabel="name"
                    option-value="productId"
                    class="org-dropdown"
                    style="width: 0% !important;"
                    filter
                    filter-placeholder="Search"
                />
                <DesignInput
                    :hint="$t('productPage.parentProduct')"
                    :custom-value="parentProductName"
                    :tooltip-text="$t('productPage.parentProductTooltip')"
                    @click="showParentProductDropdown"
                    />
            </div>

            <div class="row">
            <DesignInput
                v-model:customValue="tags"
                :hint="$t('productPage.tags')"
                :tooltip-text="$t('productPage.tagsTooltip')"
              />
            </div>
      <div class="row mt-3">
                <div class="col-12">
                    <design-multiple-options
                        @optionClicked="toggleAllowedOptionGroup"
                        :hint="$t('productPage.productGroupOptions')"
                        :options="productGroupsExcludingCurrent"
                        valuePropName="name"
                        valuePropId="productGroupId"
                        :selectedItems="allowedOptionGroups"
                    />
                </div>
                <div class="col-12 mt-2">
                    <design-select
                        :fullSize="true"
                        :hint="$t('productPage.multiChoice')"
                        v-model:customValue="freeOptionGroupsMultiChoice"
                    >
                        <option :value="true">{{ $t("generic.yes") }}</option>
                        <option :value="false">{{ $t("generic.no") }}</option>
                    </design-select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <design-multiple-options
                        @optionClicked="toggleAllowedOptionGroupPaid"
                        :hint="$t('productPage.productGroupOptionsPaid')"
                        :options="productGroupsExcludingCurrent"
                        valuePropName="name"
                        valuePropId="productGroupId"
                        :selectedItems="allowedOptionGroupsPaid"
                    />
                </div>
                <div class="col-12 mt-2">
                    <design-select
                        :fullSize="true"
                        :hint="$t('productPage.multiChoice')"
                        v-model:customValue="paidOptionGroupsMultiChoice"
                    >
                        <option :value="true">{{ $t("generic.yes") }}</option>
                        <option :value="false">{{ $t("generic.no") }}</option>
                    </design-select>
                </div>
            </div>
        </div>
        <div class="form-input-container" v-if="user.orgId !== currentProduct.orgId && currentProduct.orgId">
            <div class="row">
                <div class="col-12">
                    <div class="mr-1">
                        <DesignInput
                            v-model:customValue="stock"
                            type="number"
                            :hint="$t('productPage.stock')"
                        />
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <DesignInput
                        v-model:customValue="promoPercent"
                        type="number"
                        :hint="$t('productPage.promo_percent')"
                    />
                </div>
            </div>

        </div>

        <transition name="fade">
            <p v-if="productRequest.message" class="text-status error">
                {{ productRequest.message }}
            </p>
        </transition>
        <div class="button-container pb-5 row">
            <design-button class="button col-lg-12" @customClick="cancel()" type="secondary">
                {{ $t("generic.cancel") }}
            </design-button>
            <design-button
                class="button col-lg-12 p-3"
                @customClick="submitGroup()"
                :loading="productRequest.loading"
            >
                {{ $t("generic.save") }}
            </design-button>
        </div>
        &nbsp;
    </div>
</template>

<script>
import DesignInput from "../components/DesignInput.vue";
import DesignButton from "../components/DesignButton.vue";
import DesignSelect from "../components/DesignSelect.vue";
import {mapMutations, mapActions, mapState, mapGetters} from "vuex";
import DesignMultipleOptions from "../components/DesignMultipleOptions.vue";
import DesignInputArea from "@/views/components/DesignInputArea.vue";
import {organisation} from "@/store/organisation.module";
import DesignInputColorList from "@/views/components/DesignInputColorList.vue";
import Dropdown from 'primevue/dropdown';


export default {
    name: "EditProductDialog",
    components: {
        DesignInputColorList,
        DesignInputArea,
        DesignInput,
        DesignButton,
        DesignSelect,
        DesignMultipleOptions,
        Dropdown
    },
    data() {
        return {
            barcodeList: "",
            barcodeToAdd: "",
            selectedImage: null,
            name: "",
            priceVatInclIn: 0,
            promoPercent: 0,
            priceVatInclOut: 0,
            description: "",
            userHidden: false,
            webHidden: false,
            stock: 0,
            orderIndex: 0,
            timedProduct: false,
            weightPrice: false,
            allowDecimalUnits: false,
            dynamicPrice: false,
            selectedProductGroupId: "",
            selectedProductGroup: {},
            productTvaGroup: [
                {name: "0%", value: 0},
                {name: "6%", value: 6},
                {name: "12%", value: 12},
                {name: "21%", value: 21},
            ],
            selectedTvaInGroup: {},
            selectedTvaOutGroup: {},
            optionProductGroups: [],
            allowedOptionGroups: [],
            allowedOptionGroupsPaid: [],
            productGroupsExcludingCurrent: [],
            freeOptionGroupsMultiChoice: false,
            paidOptionGroupsMultiChoice: false,
            isNew: false,
            color: "",
            productColor: "",
            imageUrl: "",
            extraImageUrls: "",
            attachedFile: null,

            currentLanguage: 'FR',  // Default language
            descriptionFR: '',
            descriptionNL: '',
            descriptionEN: '',
            parentProductId: null,
            parentProductName: "",
            tags: '',
        };
    },
    methods: {
        ...mapMutations("product", ["setCurrentProduct", "setProductRequest"]),
        ...mapActions("productGroup", ["getProductGroups"]),
        ...mapActions("product", ["updateProduct", "addProduct"]),
        ...mapActions("images", ["fetchImages"]),
      toggleDropdown(refName) {
          const dropdown = this.$refs[refName];

          if (dropdown) {
            dropdown.$el.click();
          } else {
            console.error(`Dropdown ${refName} non trouvé`);
          }
      },
      addBarcode() {
        if (this.barcodeToAdd.trim() !== "") {
          if (!this.barcodeList) {
            this.barcodeList = this.barcodeToAdd.trim();
          } else {
            this.barcodeList += "," + this.barcodeToAdd.trim();
          }
          this.barcodeToAdd = "";
        }
      },
      removeBarcode(index) {
        let barcodesArray = this.barcodeList.split(",");
        barcodesArray.splice(index, 1);
        this.barcodeList = barcodesArray.join(",");
      },
      handleImageError(image) {
        image.imageUrl = "";
      },
        submitGroup() {
            const productToSend = this.currentProduct;
            productToSend.name = this.name;
            productToSend.vatIn = this.selectedTvaInGroup;
            productToSend.vatOut = this.selectedTvaOutGroup;
            productToSend.priceVatInclIn = Number(this.priceVatInclIn).toFixed(2);
            productToSend.priceVatInclOut = Number(this.priceVatInclOut).toFixed(2);
            if (this.barcodeList) {
              productToSend.productBarcodes = this.barcodeList
                  .split(",")
                  .map(barcode => barcode.trim())
                  .filter(barcode => barcode !== "");
            } else {
              productToSend.productBarcodes = [];
            }
            productToSend.stock = Number(this.stock);
            productToSend.orderIndex = Number(this.orderIndex);
            productToSend.productGroupId = this.selectedProductGroup.productGroupId;
            productToSend.productGroup = this.selectedProductGroup;
            productToSend.timedProduct = this.timedProduct;
            productToSend.weightPrice = this.weightPrice;
            productToSend.allowDecimalUnits = this.allowDecimalUnits;
            productToSend.dynamicPrice = this.dynamicPrice;
            productToSend.allowedOptionGroups = this.createAllowedOptionsToSend();
            productToSend.color = this.productColor;
            productToSend.imageUrl = this.imageUrl;
            productToSend.extraImageUrls = this.extraImageUrls;
            productToSend.userHidden = this.userHidden;
            productToSend.webHidden = this.webHidden;
            productToSend.promoPercent = this.promoPercent;
            productToSend.parentProductId = this.parentProductId;
            productToSend.tags = this.tags;
            productToSend.description = {};
            if (this.descriptionEN) {
              productToSend.description.en = this.descriptionEN;
            }
            if (this.descriptionFR) {
              productToSend.description.fr = this.descriptionFR;
            }
            if (this.descriptionNL) {
              productToSend.description.nl = this.descriptionNL;
            }
            const productRequestObject = {
                product: productToSend,
                file: this.attachedFile,
                orgId: this.user.orgId,
                extraFiles: this.extraAttachedFiles
            };

            if (this.isNew) {
                this.addProduct(productRequestObject);
            } else {
                this.updateProduct(productRequestObject);
            }
        },
        showParentProductDropdown() {
            this.$refs.parentProductDropdown.show(true);
        },
        createAllowedOptionsToSend() {
            var generatedAllowedOptionsToSend = [];
            this.allowedOptionGroups.forEach((el) => {
                var optionGroup = {};
                optionGroup.productGroup = el;
                optionGroup.multiOptionAllowed = this.freeOptionGroupsMultiChoice;
                optionGroup.optionType = "FREE";
                generatedAllowedOptionsToSend.push(optionGroup);
            });
            this.allowedOptionGroupsPaid.forEach((el) => {
                var optionGroup = {};
                optionGroup.productGroup = el;
                optionGroup.multiOptionAllowed = this.paidOptionGroupsMultiChoice;
                optionGroup.optionType = "PAID";
                generatedAllowedOptionsToSend.push(optionGroup);
            });
            return generatedAllowedOptionsToSend;
        },
        cancel() {
            this.setCurrentProduct(null);
        },
        hasEmptyValue(inputValue) {
            if (
                this.productRequest.message &&
                (inputValue === "" || inputValue === 0)
            ) {
                return true;
            }
            return false;
        },
        toggleAllowedOptionGroup(option) {
            const foundArray = this.allowedOptionGroups.filter(
                (e) => e.productGroupId == option.productGroupId
            );
            if (foundArray.length > 0) {
                this.allowedOptionGroups = this.allowedOptionGroups.filter(
                    (x) => x.productGroupId !== option.productGroupId
                );
            } else {
                this.allowedOptionGroups.push(option);
            }
        },
        toggleAllowedOptionGroupPaid(option) {
            const foundArray = this.allowedOptionGroupsPaid.filter(
                (e) => e.productGroupId == option.productGroupId
            );
            if (foundArray.length > 0) {
                this.allowedOptionGroupsPaid = this.allowedOptionGroupsPaid.filter(
                    (x) => x.productGroupId !== option.productGroupId
                );
            } else {
                this.allowedOptionGroupsPaid.push(option);
            }
        },
        filterOptionGroups(currentProductGroupId) {
            if (currentProductGroupId == null) {
                currentProductGroupId = this.selectedProductGroupId;
            }
            //update allowedOptionGroups based on selected group -> cant add options that's the same as parent group
            const updatedOptionGroups = this.productGroups.filter(function (element) {
                return element.productGroupId != currentProductGroupId;
            });

            this.productGroupsExcludingCurrent = updatedOptionGroups;

            //remove any selected groups that are same as parent group
            this.allowedOptionGroups = this.allowedOptionGroups.filter(function (el) {
                return el.productGroupId != currentProductGroupId;
            });

            this.allowedOptionGroupsPaid = this.allowedOptionGroupsPaid.filter(
                function (el) {
                    return el.productGroupId != currentProductGroupId;
                }
            );
        },
        loadParentProductNameById(productId) {
            if (this.products) {
                let matchedProduct = this.products.filter((obj) => {
                    return obj.productId === productId;
                })[0];
                if (matchedProduct) {
                    this.parentProductName = matchedProduct.name;
                }
            }
        }
    },
    computed: {
        organisation() {
            return organisation
        },
        ...mapState("productGroup", ["productGroups"]),
        ...mapState("product", ["currentProduct", "productRequest", "products"]),
        ...mapState("account", ["user"]),
        ...mapState('images', ["images"]),


    },
    mounted() {
        this.fetchImages();
        if (!$.isEmptyObject(this.currentProduct)) {
            this.isNew = false;
            this.name = this.currentProduct.name;
            this.selectedTvaInGroup = this.currentProduct.vatIn;
            this.selectedTvaOutGroup = this.currentProduct.vatOut;
            this.priceVatInclIn = this.currentProduct.priceVatInclIn;
            this.priceVatInclOut = this.currentProduct.priceVatInclOut;

            if (this.currentProduct.productBarcodes && Array.isArray(this.currentProduct.productBarcodes)) {
              this.barcodeList = this.currentProduct.productBarcodes.join(",");
            } else {
              this.barcodeList = "";
            }

            this.stock = this.currentProduct.stock;
            this.orderIndex = this.currentProduct.orderIndex;
            this.selectedProductGroup = this.currentProduct.productGroup;
            this.selectedProductGroupId = this.currentProduct.productGroup.productGroupId;
            this.timedProduct = this.currentProduct.timedProduct;
            this.weightPrice = this.currentProduct.weightPrice;
            this.allowDecimalUnits = this.currentProduct.allowDecimalUnits;
            this.dynamicPrice = this.currentProduct.dynamicPrice;
            this.imageUrl = this.currentProduct.imageUrl;
            this.extraImageUrls = this.currentProduct.extraImageUrls;
            this.userHidden = this.currentProduct.userHidden;
            this.webHidden = this.currentProduct.webHidden;
            this.promoPercent = this.currentProduct.promoPercent;
            this.orgId = this.currentProduct.orgId;
            this.descriptionNL = this.currentProduct?.description?.['nl'];
            this.descriptionFR = this.currentProduct?.description?.['fr'];
            this.descriptionEN = this.currentProduct?.description?.['en'];
            this.tags = this.currentProduct?.tags;
            var paidGroupsFromOptions = [];
            var freeGroupsFromOptions = [];
            this.currentProduct.allowedOptionGroups.forEach((el) => {
                if (el.optionType == "FREE") {
                    freeGroupsFromOptions.push(el.productGroup);
                    this.freeOptionGroupsMultiChoice = el.multiOptionAllowed;
                } else if (el.optionType == "PAID") {
                    paidGroupsFromOptions.push(el.productGroup);
                    this.paidOptionGroupsMultiChoice = el.multiOptionAllowed;
                }
            });
            this.allowedOptionGroups = freeGroupsFromOptions;
            this.allowedOptionGroupsPaid = paidGroupsFromOptions;
            this.productColor = this.currentProduct.color;
            this.parentProductId = this.currentProduct.parentProductId;
        } else {
            this.isNew = true;
        }

        if (this.productGroups.length === 0) {
            this.getProductGroups();
        } else {
            this.filterOptionGroups(this.selectedProductGroupId);
        }
    },
    watch: {
        parentProductId(newP, oldP) {
            if (newP) {
                this.loadParentProductNameById(newP);
            }
        },
        productGroups(newProductGroups, oldProductGroups) {
            if (newProductGroups.length > oldProductGroups.length) {
                this.filterOptionGroups();
            }
        },
        selectedProductGroupId(newP, oldP) {
            if (this.productGroups && newP) {
                let matchedGroup = this.productGroups.filter((obj) => {
                    if (obj.productGroupId == newP) {
                        this.selectedTvaInGroup = obj.vatIn;
                        this.selectedTvaOutGroup = obj.vatOut;
                    }
                    return obj.productGroupId == newP;
                })[0];
                if (matchedGroup) {
                    this.selectedProductGroup = matchedGroup;
                }
                if (this.isNew) {
                    this.vatIn = this.selectedProductGroup.vatIn;
                    this.vatOut = this.selectedProductGroup.vatOut;
                }
                this.filterOptionGroups(newP);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.slot-box{
  background-color: rgba(129, 137, 201, 0.18);
  padding: 10px;
  border-radius: 8px;
  min-height: 80px;
  color: #efefef;
  text-align: start;
  justify-content: start;
  font-size: 14px;
  ul{
    padding: 0 !important;
    margin: 0 !important;

  }
  li{
    text-decoration: none;
    list-style: none
  }
}

.addBtn{
  width: auto !important;
  height: auto;
  padding: 10px 13px;
}

.delete{
  padding: 8px !important;
  border: none;
  cursor: pointer;
  display: flex;
  border-radius: 8px !important;
  color: #DD6365 !important;
  background-color: rgba(129, 137, 201, 0.18);
}

.description-zone {
  display: flex;
  align-items: flex-end;
}

.description-content {
  flex: 1;
}

.btn-language-zone {
  display: flex;
  flex-direction: column;

}

.bread-crumb-base{
  margin-right: 0 !important;

}

.slot-item{
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 10px;
  max-width: 100%;
}

.slot-item span {
  word-break: break-all;
}

.input-row {
  display: flex;
  gap: 5px;
  align-items: center;
  max-width: 100%;
}

.custom-check {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    input {
        margin-right: $default-edge-spacing;
    }
}
.dropdown-item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dropdown-image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 4px;
}

.dropdown-text {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main {
    width: auto;
    padding: 20px 20px;
}

select {
    padding: 10px 20px 10px 20px;
}

.form-input-container {
    display: flex;
    flex-direction: column;
    row-gap: $default-edge-spacing-l;
}

.button-container {
    display: flex;
    justify-content: stretch;
    align-content: stretch;
    column-gap: $default-edge-spacing-l;
    margin-top: $default-edge-spacing-xxl;

    .button {
        width: 100%;
    }
}

.colorPicker-style {
    padding-right: 10px;
}

.input-hint-color {
    padding: 0 !important;
    font-size: 13px;
    font-weight: normal;
    color: $color-secondary-text;
    opacity: 0.8;
    margin: 0 0 8px 8px;
}

.text-area-barcode {
    height: 100px;
}

.text-area-description {
    height: 150px;
}

.profile-data-container {
    padding: 0px 0px 30px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .letter-icon {
        color: $color-primary-text;
        background-color: $color-primary-blue;
        border-radius: 150px;
        text-align: center;
        font-weight: 400;
        font-size: 24px;
        height: 48px;
        width: 48px;
        min-width: 48px;
        min-height: 48px;
        padding: 6px;
    }

    .profile-text-container {
        flex-grow: 1;
        padding-left: $default-edge-spacing-l;
        overflow: hidden;

        .title {
            font-size: $font-title;
            color: $color-primary-text;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .subtitle {
            font-size: $font-subtitle;
            color: $color-secondary-text;
        }
    }

    .delete-button {
        min-width: 18px;
        max-width: 18px;
        margin-right: $default-edge-spacing-xl;
        stroke: $color-pastel-red;
    }
}

.active-language {
    background-color: #8393F4; /* Change this to your desired color */
    color: white; /* Text color */
}

@media (max-width: $screen-md) {
    .button-container {
        justify-content: center;
        flex-direction: column;
        padding-top: $default-edge-spacing;

        .button {
            margin-top: $default-edge-spacing-l;

            &:nth-child(1) {
                padding-right: 0 !important;
            }
        }
    }

    .text-area-barcode {
        height: 100px;
    }


    .color-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }

    .color-button {
        width: 31px; /* Default size for mobile */
        height: 31px; /* Default size for mobile */
        border: 2px solid transparent;
        border-radius: 5px;
        cursor: pointer;
        outline: none;
        padding: 1px;
        transition: border-color 0.3s, box-shadow 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .color-button.selected {
        box-shadow: 0 0 0 3px #8393F4; /* Change this to desired highlight style */
    }


  @media (min-width: 768px) {
        .color-button {
            width: 52px; /* Adjusted size for desktop */
            height: 45px; /* Adjusted size for desktop */
            padding: 4px; /* Adjust padding if necessary */
        }
    }
}
</style>