<template>
  <div>
    <popup-view :isVisible="kitchenState.editingKitchen || kitchenState.isAddingKitchen"
                @close="setEditingKitchen(null); setIsAddingKitchen(false)"
    >
      <edit-message-dialog/>
    </popup-view>
    <popup-view :isVisible="deleteOpenDialog" @close="deleteOpenDialog = false">
      <confirm-delete-dialog :product="currentSelect" @submitDeleteAction="submitDelete"
                             @cancelDeleteAction="deleteOpenDialog = false"/>
    </popup-view>

    <page-header
        class="header"
        @actionClick="setIsAddingKitchen(true)"
        iconName="kitchen2.svg"
        actionIconName="plus.svg"
        :actionText="$t('kitchenPageStrings.addMessage')"
        :headerTitle="$t('kitchenPageStrings.name')"
    />

    <DataTable
        :value="kitchenState.kitchen"
        v-model:filters="filters"
        filter-display="row"
        table-style="min-width: 50rem"
        :loading="loading"
        :global-filter-fields="['text']"
    >
      <template #header>
        <div class="flex justify-content-end" style="margin-top: 15px">
            <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Keyword Search"/>
            </span>
        </div>
      </template>

      <template #empty> {{ $t('kitchenPageStrings.noFundResultTable') }}</template>
      <template #loading> {{ $t('kitchenPageStrings.loadingResultTable') }}</template>

      <Column field="text" header="Message" sortable></Column>

      <Column field="quantity" header="Actions">
        <template #body="{data}">
          <div class="edit-row-btn">
            <button class="delete" @click="openDeleteDialog(data)">
              <inline-svg :src="require('@/assets/icons/trash.svg')"/>
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import {ref} from 'vue';
import {mapActions, mapState, mapMutations} from "vuex";
import {FilterMatchMode} from 'primevue/api';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import PageHeader from './components/PageHeader.vue';
import PopupView from './components/PopupView.vue';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog.vue';
import {isNumber} from "chart.js/helpers";
import EditMessageDialog from "@/views/dialogs/EditMessageDialog.vue";

export default {
  name: "KitchenPage",
  components: {
    DataTable,
    Column,
    InputText,
    PopupView,
    PageHeader,
    ConfirmDeleteDialog,
    EditMessageDialog
  },
  data() {
    return {
      filters: ref({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        name: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        printer: {value: null, matchMode: FilterMatchMode.STARTS_WITH}
      }),
      deleteOpenDialog: false,
      currentSelect: {}
    }
  },
  computed: {
    ...mapState({
      kitchenState: 'kitchen'
    })
  },
  methods: {
    ...mapActions('kitchen', ['getKitchen', 'deleteKitchen']),
    ...mapMutations('kitchen', ['setIsAddingKitchen', 'setEditingKitchen']),
    openDeleteDialog(item) {
      this.deleteOpenDialog = true;
      this.currentSelect = item;
    },
    submitDelete() {
      this.deleteKitchen(this.currentSelect.id);
      this.deleteOpenDialog = false;
    }
  },
  mounted() {
    this.getKitchen();
  }
}
</script>

<style lang="scss" scoped>
.edit-row-btn {
  column-gap: 10px;
  background: none !important;
}

.row-icon {
  width: 18px;
  height: 18px;

  &.disabled {
    color: $color-secondary-text !important;
  }
}
</style>