<template>
  <div class="main">
    <div class="form-input-container">
      <DesignInput v-model:customValue="name" :hint="$t('productGroupPage.name')"/>

      <div class="row">
        <div class="col-6">
          <div class="mr-1">
            <design-select :hint="$t('productGroupPage.vatIn')" v-model:customValue="selectedTvaInGroup">
              <option v-for="item in productTvaGroup" :key="item.value" :value="item.value">{{ item.name }}</option>
            </design-select>
          </div>
        </div>
        <div class="col-6">
          <design-select :hint="$t('productGroupPage.vatOut')" :tooltip-text="$t('productGroupPage.vatTooltip')"
                         v-model:customValue="selectedTvaOutGroup">
            <option v-for="item in productTvaGroup" :key="item.value" :value="item.value">{{ item.name }}</option>
          </design-select>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
            <p class="titleSelect">{{this.$t('printerStrings.printerName')}}</p>
            <div class="multiselect-container">
              <MultiSelect
                  v-model="selectedPrinters"
                  :options="Object.values(printers)"
                  optionLabel="name"
                  style="width: 100%"
                  @update:modelValue="updatePrintersObject"
              />
              <span class="dropdown-icon">
                <inline-svg class="action-icon" :src="require('@/assets/icons/arrow.svg')" />
              </span>
            </div>
          </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="mr-1">
            <DesignInput
                v-model:customValue="orderIndex"
                type="number"
                :hint="$t('productPage.orderIndex')"
                :tooltip-text="$t('productGroupPage.orderToolltip')"
            />
          </div>
        </div>

        <div class="col-6">
          <DesignInput
              v-model:customValue="optionOrderIndex"
              type="number"
              :hint="$t('productPage.optionOrderIndex')"
              :tooltip-text="$t('productGroupPage.optionOrderTooltip')"
          />
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-6">
        <div class="mt-3 mr-1">
          <design-select :fullSize="true" :hint="$t('productGroupPage.userHidden')"
                         v-model:customValue="userHidden"
                         :tooltip-text="$t('productGroupPage.userHiddenTooltip')"
          >
            <option :value="true">{{ $t('generic.yes') }}</option>
            <option :value="false">{{ $t('generic.no') }}</option>
          </design-select>
        </div>
      </div>
      <div class="col-6">
        <div class="mt-3 mr-1">
          <design-select :fullSize="true" :hint="$t('productGroupPage.webHidden')"
                         v-model:customValue="webHidden"
                         :tooltip-text="$t('productGroupPage.userHiddenTooltip')"
          >
            <option :value="true">{{ $t('generic.yes') }}</option>
            <option :value="false">{{ $t('generic.no') }}</option>
          </design-select>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="mt-4 form-input-container">
        <design-input-color-list :hint="$t('generic.color')" :custom-value="groupColor"
                                 v-model:custom-value="groupColor"/>
      </div>
    </div>

    <div class="row mt-3">
      <DesignInput
          v-model:customValue="imageUrl"
          v-model:attachedFile="attachedFile"
          :hint="$t('productPage.imageUrl')"
          :file-attach-enabled="true"
          @click="toggleDropdown('imageDropdown1')"
      />
      <Dropdown
          ref="imageDropdown1"
          v-model="imageUrl"
          :options="images"
          optionLabel="imageUrl"
          optionValue="imageUrl"
          style="width: 0 !important;"
          class="org-dropdown"
          filter
          filterPlaceholder="Search"
      >

        <template #option="slotProps">
          <div class="dropdown-item">
            <img :src="slotProps.option.imageUrl" alt="Preview" class="dropdown-image"/>
            <span class="dropdown-text">{{ slotProps.option.imageUrl.replace("https://posbel-images-upload.s3.amazonaws.com/", "") }}</span>
          </div>
        </template>
      </Dropdown>
    </div>
    <div class="row mt-3">
      <div class="col-6 ">
        <div class="mr-1">
          <design-select
              :hint="$t('productGroupPage.maxChoicePaid')"
              v-model:customValue="maxChoicePaidPreselect"
              :tooltip-text="$t('productGroupPage.maxChoicePaidToolTip')"
          >
            <option value="0">Single</option>
            <option value="1">Unlimited</option>
            <option value="-1">Custom</option>
          </design-select>
          <DesignInput
              v-if="maxChoicePaidPreselect == -1"
              v-model:customValue="maxChoicePaid"
              type="number"
          />
        </div>
      </div>
      <div class="col-6">
        <design-select
            :hint="$t('productGroupPage.maxChoiceFree')"
            v-model:customValue="maxChoiceFreePreselect"
            :tooltip-text="$t('productGroupPage.maxChoiceFreeToolTip')"
        >
          <option value="0">Single</option>
          <option value="1">Unlimited</option>
          <option value="-1">Custom</option>
        </design-select>
        <DesignInput
            v-if="maxChoiceFreePreselect == -1"
            v-model:customValue="maxChoiceFree"
            type="number"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="mt-3 mr-1">
          <design-select
              :fullSize="true"
              :hint="$t('productGroupPage.KitchenPrintEnabled')"
              v-model:customValue="kitchenPrintEnabled"
              :tooltip-text="$t('productGroupPage.kitchenPrintEnabledTooltip')"
          >
            <option :value="true">{{ $t('generic.yes') }}</option>
            <option :value="false">{{ $t('generic.no') }}</option>
          </design-select>
        </div>
      </div>
      <div class="col-6 mt-3">

        <design-select
            :hint="$t('productPage.productGroupParent')"
            v-model:customValue="selectedProductGroupId"
        >
          <option value="null">None</option>
          <option
              v-for="item in productGroups"
              :key="item.productGroupId"
              :value="item.productGroupId"
          >
            {{ item.name }}
          </option>
        </design-select>
      </div>
    </div>

    <p v-if="editGroupRequest.message || addGroupRequest.message" class="text-status error">
      {{ editGroupRequest.message || addGroupRequest.message }}</p>
    <div class="button-container row">
      <design-button class="button col-lg-12" @customClick="cancel()" type="secondary"> {{ $t('generic.cancel') }}</design-button>
      <design-button class="button col-lg-12 p-2" @customClick="submitGroup()"
                     :loading="editGroupRequest.loading || addGroupRequest.loading"> {{ $t('generic.save') }}
      </design-button>
    </div>
  </div>
</template>

<script>
import DesignInput from '../components/DesignInput.vue';
import DesignButton from '../components/DesignButton.vue';
import DesignSelect from '../components/DesignSelect.vue';
import {mapMutations, mapActions, mapState, mapGetters} from "vuex";
import DesignInputColorList from "@/views/components/DesignInputColorList.vue";
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';



export default {
  name: 'EditProductGroupDialog',
  components: {
    DesignInputColorList,
    DesignInput,
    DesignButton,
    DesignSelect,
    Dropdown,
    MultiSelect
  },
  methods: {
    ...mapMutations('productGroup', ['setEditingGroup']),
    ...mapActions('productGroup', ['updateProductGroup', 'addProductGroup', 'getProductGroups']),
    ...mapActions('printer', ['getPrinters']),
    ...mapState("productGroup", ["productGroups"]),
    ...mapActions("images", ["fetchImages"]),
    toggleDropdown(refName) {
      const dropdown = this.$refs[refName];

      if (dropdown) {
        dropdown.$el.click();
      } else {
        console.error(`Dropdown ${refName} non trouvé`);
      }
    },
    updatePrintersObject() {
      this.printersObject = this.selectedPrinters.map(printer => ({
        id: printer.id,
        name: printer.name,
        ipAddress: printer.ipAddress,
        doublePrint: printer.doublePrint || false,
        protocol: "ESC_POS"
      }));
    },
    submitGroup: function () {
      if (this.editingGroup) {
        const newGroupData = this.editingGroup;
        newGroupData.name = this.name;
        newGroupData.orderIndex = this.orderIndex;
        newGroupData.maxChoicePaid = this.maxChoicePaid;
        newGroupData.maxChoiceFree = this.maxChoiceFree;
        newGroupData.vatIn = this.selectedTvaInGroup;
        newGroupData.vatOut = this.selectedTvaOutGroup;
        newGroupData.printerLocalIp = this.printerIp;
        newGroupData.userHidden = this.userHidden;
        newGroupData.webHidden = this.webHidden;
        newGroupData.kitchenPrintEnabled = this.kitchenPrintEnabled;
        newGroupData.color = this.groupColor;
        newGroupData.imageUrl = this.imageUrl;
        newGroupData.optionOrderIndex = this.optionOrderIndex;
        newGroupData.printer = null;
        newGroupData.printers = this.printersObject;
        newGroupData.parentGroupId = this.selectedProductGroupId;

        const productGroupRequestObject = {
          productGroup: newGroupData,
          file: this.attachedFile,
          orgId: this.user.orgId
        };

        this.updateProductGroup(productGroupRequestObject);
      } else {
        const newGroupData = {};
        newGroupData.name = this.name;
        newGroupData.orderIndex = Number(this.orderIndex);
        newGroupData.maxChoicePaid = Number(this.maxChoicePaid);
        newGroupData.maxChoiceFree = Number(this.maxChoiceFree);
        newGroupData.vatIn = this.selectedTvaInGroup;
        newGroupData.vatOut = this.selectedTvaOutGroup;
        newGroupData.printerLocalIp = this.printerIp;
        newGroupData.userHidden = this.userHidden;
        newGroupData.webHidden = this.webHidden;
        newGroupData.kitchenPrintEnabled = this.kitchenPrintEnabled;
        newGroupData.optionOrderIndex = this.optionOrderIndex;
        newGroupData.printer = null;
        newGroupData.printers = this.printersObject
        newGroupData.imageUrl = this.imageUrl;
        newGroupData.color = this.groupColor;
        newGroupData.parentGroupId = this.selectedProductGroupId;

        const productGroupRequestObject = {
          productGroup: newGroupData,
          file: this.attachedFile,
          orgId: this.user.orgId
        };

        this.addProductGroup(productGroupRequestObject);
        console.log(newGroupData);
      }
    },
    cancel() {
      this.setEditingGroup(null)
      this.selectedPrinters = [];
    },
  },
  computed: {
    ...mapState('productGroup', ['editingGroup', 'editGroupRequest', 'addGroupRequest', 'getGroupsRequest', 'productGroups']),
    ...mapState('printer', ['printers']),
    ...mapState("account", ["user"]),
    ...mapState('images', ['images'])
  },
  data() {
    return {
      name: "",
      printerIp: "",
      color: "",
      groupColor: "",
      userHidden: false,
      webHidden: false,
      selectedPrinterId: null,
      selectedPrinters: [],
      printersObject: [],
      kitchenPrintEnabled: true,
      orderIndex: 0,
      optionOrderIndex: 0,
      maxChoiceFree: 0,
      maxChoicePaid: 0,
      productTvaGroup: [
        {"name": "0%", "value": 0},
        {"name": "6%", "value": 6},
        {"name": "12%", "value": 12},
        {"name": "21%", "value": 21},
      ],
      selectedTvaInGroup: {},
      selectedTvaOutGroup: {},
      parentGroupId: "",
      maxChoicePaidPreselect: 1,
      maxChoiceFreePreselect: 1,
      imageUrl: "",
      attachedFile: null,
    }
  },
  mounted() {
    this.fetchImages();
    this.getPrinters().then(() => {
      if (this.editingGroup) {
        this.selectedPrinters = this.editingGroup.printers.map(printer => {
          return Object.values(this.printers).find(p => p.id === printer.id) || printer;
        });
        this.updatePrintersObject();
        this.name = this.editingGroup.name;
        this.orderIndex = this.editingGroup.orderIndex;
        this.maxChoicePaid = this.editingGroup.maxChoicePaid;
        this.maxChoiceFree = this.editingGroup.maxChoiceFree;
        this.selectedTvaInGroup = String(this.editingGroup.vatIn);
        this.selectedTvaOutGroup = String(this.editingGroup.vatOut);
        this.printerIp = this.editingGroup.printerLocalIp;
        this.userHidden = this.editingGroup.userHidden;
        this.webHidden = this.editingGroup.webHidden;
        this.kitchenPrintEnabled = this.editingGroup.kitchenPrintEnabled;
        this.optionOrderIndex = this.editingGroup.optionOrderIndex;
        this.imageUrl = this.editingGroup.imageUrl;

        if (this.editingGroup.color) {
          this.groupColor = this.editingGroup.color;
        }
        this.selectedProductGroupId = this.editingGroup.parentGroupId;

        // Set max paid/free preselectors
        if (this.maxChoicePaid == 1) {
          this.maxChoicePaidPreselect = 1;
        } else if (this.maxChoicePaid == 0) {
          this.maxChoicePaidPreselect = 0;
        } else {
          this.maxChoicePaidPreselect = -1;
        }

        if (this.maxChoiceFree == 1) {
          this.maxChoiceFreePreselect = 1;
        } else if (this.maxChoiceFree == 0) {
          this.maxChoiceFreePreselect = 0;
        } else {
          this.maxChoiceFreePreselect = -1;
        }
      }
    })

    if ($.isEmptyObject(this.printers)) {
      this.getPrinters();
    }
    this.parentGroupId = this.editingGroup?.parentGroupId;
  },
  watch: {
    maxChoicePaidPreselect(newVal, oldVal) {
      if (newVal >= 0) {
        this.maxChoicePaid = newVal;
      }
    },
    maxChoiceFreePreselect(newVal, oldVal) {
      if (newVal >= 0) {
        this.maxChoiceFree = newVal;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.multiselect-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 !important;
}
.action-icon {
  width: 14px;
  height: 14px;
  fill: white !important;
  display: block;
  margin-right: 10px;
}

.dropdown-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  pointer-events: none;
}

.titleSelect {
  padding: 0 !important;
  font-size: 13px;
  font-weight: normal;
  color: $color-secondary-text;
  opacity: 0.8;
  margin: 0 0 8px 8px;
}

.main {
  width: auto;
  padding: 20px 20px;
}

select {
  padding: 10px 20px 10px 20px;
}

.form-input-container {
  display: flex;
  flex-direction: column;
  row-gap: $default-edge-spacing-l;
}

.button-container {
  display: flex;
  justify-content: stretch;
  align-content: stretch;
  column-gap: $default-edge-spacing-l;
  margin-top: $default-edge-spacing-xxl;
  padding-bottom: 70px;

  .button {
    width: 100%;
  }
}

.colorPicker-style {
  padding-right: 10px;
}

.input-hint-color {
  padding: 0 !important;
  font-size: 13px;
  font-weight: normal;
  color: $color-secondary-text;
  opacity: 0.8;
  margin: 0 0 8px 8px;
}

.profile-data-container {
  padding: 0 0 30px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .letter-icon {
    color: $color-primary-text;
    background-color: $color-primary-blue;
    border-radius: 150px;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    padding: 6px;
  }

  .profile-text-container {
    flex-grow: 1;
    padding-left: $default-edge-spacing-l;
    overflow: hidden;

    .title {
      font-size: $font-title;
      color: $color-primary-text;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .subtitle {
      font-size: $font-subtitle;
      color: $color-secondary-text;
    }
  }

  .delete-button {
    min-width: 18px;
    max-width: 18px;
    margin-right: $default-edge-spacing-xl;
    stroke: $color-pastel-red;

  }
}

.color-picker {
  height: 50px !important;
}

@media (max-width: $screen-md) {
  .button-container {
    justify-content: center;
    flex-direction: column;
    padding-top: $default-edge-spacing;

    .button {
      margin-top: $default-edge-spacing-l;

      &:nth-child(1) {
        padding-right: 0 !important;
      }
    }
  }
}

.custom-check {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  input {
    margin-right: $default-edge-spacing;
  }
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dropdown-image {
  width: 30px; /* Taille du carré */
  height: 30px;
  object-fit: cover;
  border-radius: 4px;
}

.dropdown-text {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


</style>