<template>
  <div class="main">
    <div class="form-input-container">
      <div class="row">
        <div
            class="input-container"
            @dragover.prevent="dragOver = true"
            @dragleave.prevent="dragOver = false"
            @drop.prevent="onDropFiles"
            :class="{ 'drag-active': dragOver }"
        >
          <div class="drop-zone" @click="onAttachFileClicked">
            <p class="drop-text">{{$t('imagePageStrings.textUpload')}}</p>
            <input
                type="file"
                ref="fileInput"
                accept="image/*"
                :multiple="true"
                @change="onFilePicked"
                style="display: none"
            />
            <inline-svg
                class="trailing-icon"
                :src="require('@/assets/icons/paperclip.svg')"
            ></inline-svg>
          </div>

          <div class="image-preview-list" v-if="attachedFilePreview.length">
            <div class="image-preview-container" v-for="(image, index) in attachedFilePreview" :key="index">
              <img class="image-preview" :src="image" alt="Preview"/>
              <span class="delete-icon" @click="removeImage(index)">✖</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button-container row">
      <design-button class="button col-12 col-md-6" @customClick="cancel()" type="secondary">
        {{$t('generic.cancel')}}
      </design-button>
      <design-button class="button col-12 col-md-6" @customClick="submitImage" :disabled="isLoad">
        {{$t('generic.save')}}
      </design-button>
    </div>
  </div>
</template>

<script>
import DesignButton from "../components/DesignButton.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import { toast } from "vue3-toastify";
import {nextTick} from "vue";

export default {
  name: "AddImageDialog",
  components: {
    DesignButton
  },
  data() {
    return {
      extraAttachedFiles: [],
      attachedFilePreview: [],
      imageUrl: "",
      dragOver: false,
      isLoad: false
    };
  },
  methods: {
    ...mapActions("images", ["fetchImages", "addImage"]),
    ...mapMutations('images', ['setEditingImage']),
    ...mapState("images", ["images"]),

    onAttachFileClicked() {
      this.$refs.fileInput.click();
    },

    cancel(){
      this.setEditingImage(false);
    },

    async processFiles(files) {
      const validFiles = [];

      for (const file of files) {
        if (file.size > 2 * 1024 * 1024) {
          toast.error("The file exceeds the 2MB size limit");
        } else {
          validFiles.push(file);
        }
      }

      if (!validFiles.length) return;

      const previews = await Promise.all(
          validFiles.map(file =>
              new Promise(resolve => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.readAsDataURL(file);
              })
          )
      );

      this.extraAttachedFiles = [...this.extraAttachedFiles, ...validFiles]; // Ajoute les fichiers
      this.attachedFilePreview = [...this.attachedFilePreview, ...previews]; // Ajoute les aperçus
    },

    onFilePicked(event) {
      this.processFiles(Array.from(event.target.files));
    },

    onDropFiles(event) {
      this.dragOver = false;
      this.processFiles(Array.from(event.dataTransfer.files));
    },

    removeImage(index) {
      this.attachedFilePreview.splice(index, 1);
      this.extraAttachedFiles.splice(index, 1);
    },

    async submitImage() {
      if (this.isLoad) return;

      this.isLoad = true;

      try {
        console.log("extraAttachedFiles:", this.extraAttachedFiles);

        await this.addImage({
          orgId: this.user.orgId,
          extraAttachedFiles: this.extraAttachedFiles
        });
      } catch (error) {
        console.error("Erreur lors de l'ajout des images :", error);
      } finally {
        this.isLoad = false;
      }
    }

  },
  computed: {
    ...mapState("account", ["user"]),
    ...mapState("images", ["editingImage", "editImageRequest", "addImageRequest", "getImageRequest", "getImageRequest", "images"])
  }
};
</script>

<style lang="scss" scoped>

.main {
  width: auto;
  padding: 20px 20px;
}

select {
  padding: 10px 20px 10px 20px;
}

.form-input-container {
  display: flex;
  flex-direction: column;
  row-gap: $default-edge-spacing-l;
}

.button-container {
  margin-top: $default-edge-spacing-l;
  .button {
    width: 100%;

    &:nth-child(1) {
      padding-right: $default-edge-spacing !important;
    }
  }

}

.profile-data-container {
  padding: 0px 0px 30px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .letter-icon {
    color: $color-primary-text;
    background-color: $color-primary-blue;
    border-radius: 150px;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    padding: 6px;
  }

  .profile-text-container {
    flex-grow: 1;
    padding-left: $default-edge-spacing-l;
    overflow: hidden;
    .title {
      font-size: $font-title;
      color: $color-primary-text;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .subtitle {
      font-size: $font-subtitle;
      color: $color-secondary-text;
    }
  }

  .delete-button {
    min-width: 18px;
    max-width: 18px;
    margin-right: $default-edge-spacing-xl;
    stroke: $color-pastel-red;

  }
}

@media (max-width: $screen-md ) {
  .button-container {
    padding-top: $default-edge-spacing;
    .button {
      margin-top: $default-edge-spacing-l;

      &:nth-child(1) {
        padding-right: 0 !important;
      }
    }
  }
}

.input-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 2px dashed rgba(255, 255, 255, 0.3);
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  transition: border-color 0.3s ease-in-out;

  &.drag-active {
    border-color: #6c5ce7;
    background-color: rgba(108, 92, 231, 0.1);
  }
}

.hint-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .input-hint {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
  }

  .icon-info {
    width: 12px;
    height: 12px;
  }
}

.drop-zone {
  cursor: pointer;
  padding: 20px;
  border-radius: 8px;
  background: rgba(129, 137, 201, 0.0549019608);
  text-align: center;
  transition: background 0.3s ease-in-out;
}

.drop-text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin-bottom: 10px;
}

.url-input-container {
  display: flex;
  margin-top: 10px;
}

.url-input {
  flex: 1;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(129, 137, 201, 0.0549019608);
  border-radius: 5px;
  color: white;
  font-size: 14px;
}

.add-url-button {
  margin-left: 10px;
  padding: 10px;
  background-color: #8393FC;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: #5a4ac7;
  }
}

.image-preview-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.image-preview-container {
  position: relative;
  width: 60px;
  height: 60px;

  .image-preview {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }

  .delete-icon {
    position: absolute;
    top: 3px;
    right: 3px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
  }
}
</style>