import { kitchenService } from "@/services/kitchenService";
import { toast } from "vue3-toastify";
import { i18n } from "./../lang";

const state = {
    kitchen: [],
    isAddingKitchen: false,
    editingKitchen: false,
    editKitchenRequest: {
        loading: false
    },
    addKitchenRequest: {
        loading: false
    },
    getKitchenRequest: {
        loading: false
    },
    deleteKitchenRequest: {
        loading: false
    }
};

const actions = {
    getKitchen({commit}){
        commit('setGetKitchenRequest', {loading: true})
        kitchenService.getKitchen()
            .then((response) => {
                commit('setGetKitchenRequest', {loading: false})
                commit('updateKitchen', response.data);
            })
            .catch((error) => commit('setGetKitchenRequest', {loading: false, message: error}))
    },
    async addKitchen({commit, state}, text){
        console.log('fff',text)
        commit('setAddKitchenRequest', {loading: true});
        kitchenService.addKitchen(text)
            .then((response) => {
                toast.success(i18n.global.t("toast.createSuccess"));
                const updatedKitchen = state.kitchen;
                updatedKitchen.push(response.data)
                commit('setAddKitchenRequest', {loading: false});
                commit('updateKitchen', updatedKitchen);
                commit('setIsAddingKitchen', false);
            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.createError"));
                commit('setAddKitchenRequest', {loading: false, message: error})
            })
    },
    deleteKitchen({commit, state}, kitchenMessageId) {
        kitchenService.deleteKitchen(kitchenMessageId)
            .then(() => {
                toast.success(i18n.global.t("toast.deleteSuccess"));
                const updatedKitchenList = state.kitchen.filter(element => element.id  !== kitchenMessageId);
                commit('updateKitchen', updatedKitchenList);
            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.deleteError"));
                commit('setDeleteKitchenRequest', { loading: false, message: error });
            });
    }
};

const mutations = {
    setEditingKitchen(state, editingKitchen){
        state.editingKitchen = editingKitchen;
        state.isAddingKitchen = false;
    },
    setGetKitchenRequest(state, {loading, message}){
        state.getkitchenRequest = {
            loading: loading,
            message: message
        }
    },
    setAddKitchenRequest(state, {loading, message}){
        state.addKitchenRequest = {
            loading: loading,
            message: message
        }
    },
    setEditKitchenRequest(state, {loading, message}){
        state.editKitchenRequest = {
            loading: loading,
            message: message
        }
    },
    setDeleteKitchenRequest(state, {loading, message}){
        state.deleteKitchenRequest = {
            loading: loading,
            message: message
        }
    },
    updateKitchen(state, newList){
        state.kitchen = newList;
    },
    setIsAddingKitchen(state, isAddingKitchen){
        state.isAddingKitchen = isAddingKitchen;
        state.editingKitchen = null;
    }
};

export const kitchen = {
    namespaced: true,
    state,
    mutations,
    actions,
};
