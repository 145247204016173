<template>
  <div class="main">
    <div class="form-input-container">
      <div class="row">
        <div class="col-6">
          <div class="mr-1">
            <DesignInput
                v-model:customValue="name"
                type="text"
                :hint="$t('pickupPageStrings.name')"
            />
            <p v-if="errors.name" class="error-message">{{ errors.name }}</p>
          </div>
        </div>
        <div class="col-6">
          <DesignInput
              v-model:customValue="phoneNumber"
              type="text"
              :hint="$t('pickupPageStrings.phone')"
          />
          <p v-if="errors.phone" class="error-message">{{ errors.phone }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="mr-1">
            <DesignInput
                v-model:customValue="address"
                type="text"
                :hint="$t('pickupPageStrings.address')"
            />
            <p v-if="errors.address" class="error-message">{{ errors.address }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p class="input-hint">{{$t('pickupPageStrings.hoursField')}}</p>
          <div class="slot-box">
            <p class="title">{{$t('pickupPageStrings.openingHours')}}</p>
            <ul v-if="slotOrder.length">
              <li v-for="(item, index) in slotOrder" :key="'order' + index" class="slot-item">
                {{ item.day }} :<br v-if="isMobile"> {{ item.startHour }} - {{ item.endHour }}
                <button class="delete" @click="removeSlot(index, 'order')">
                  <inline-svg :src="require('@/assets/icons/trash.svg')" style="width: 18px; height: 18px; "/>
                </button>
              </li>
            </ul>
            <p v-else>N/A</p>
            <hr />
            <p class="title">{{$t('pickupPageStrings.pickupTime')}}</p>
            <ul v-if="slotPickup.length">
              <li v-for="(item, index) in slotPickup" :key="'pickup' + index" class="slot-item">
                {{ item.day }} : <br v-if="isMobile">{{ item.startHour }} - {{ item.endHour }}
                <button class="delete" @click="removeSlot(index, 'pickup')">
                  <inline-svg :src="require('@/assets/icons/trash.svg')" style="width: 18px; height: 18px; " />
                </button>
              </li>
            </ul>
            <p v-else>N/A</p>
          </div>

        </div>
        <div class="col-6">
          <div class="mr-1 customDrop">
            <p class="input-hint">{{$t('pickupPageStrings.type')}}</p>
            <Dropdown  v-model="selectedType"  :options="type" optionLabel="name" class="dropInput w-100"  />
            <p v-if="errors.day" class="error-message">{{ errors.day }}</p>
          </div>
          <div class="mr-1 customDrop mt-2">
            <p class="input-hint">{{$t('pickupPageStrings.day')}}</p>
            <Dropdown  v-model="selectedDay"  :options="days" optionLabel="name" class="dropInput w-100"  />
            <p v-if="errors.day" class="error-message">{{ errors.day }}</p>
          </div>
          <div class="input-row">
            <DesignInput
                v-model:customValue="startHour"
                type="text"
                @input="formatTimeInput"
                :hint="$t('pickupPageStrings.hour')"
                class="time-input"
            />
            <DesignInput
                v-model:customValue="endHour"
                type="text"
                @input="formatTimeInput"
                class="time-input"
                :hint="$t('pickupPageStrings.hour2')"
            />
          </div>
          <design-button class="button w-100 mt-3" @customClick="addSlot">{{$t('pickupPageStrings.add')}}</design-button>
        </div>
      </div>
    </div>
    <p v-if="editPickupRequest.message || addPickupRequest.message" class="text-status error">
      {{ editPickupRequest.message || addPickupRequest.message }}</p>
    <div class="button-container row">
      <design-button class="button col-lg-12" @customClick="cancel()" type="secondary"> {{ $t('generic.cancel') }}</design-button>
      <design-button class="button col-lg-12 p-2" @customClick="submitForm()"> {{ $t('generic.save') }}
      </design-button>
    </div>
  </div>
</template>

<script>
import DesignInput from '../components/DesignInput.vue';
import DesignButton from '../components/DesignButton.vue';
import Dropdown from 'primevue/dropdown';
import {mapMutations, mapActions, mapState, mapGetters} from "vuex";

export default {
  name: 'EditPickupDialog',
  components: {
    DesignInput,
    DesignButton,
    Dropdown
  },
  methods: {
    ...mapMutations('pickup', ['setEditingPickup']),
    ...mapActions('pickup', ['addPickup', 'getPickup', 'updatePickup']),
    ...mapState("pickup", ["pickup"]),
    validateFields() {
      this.errors = {};

      if (!this.name) {
        this.errors.name = this.$t('pickupPageStrings.erName');
      }

      if (!this.address){
        this.errors.address = this.$t('pickupPageStrings.erAddress');
      }

      if (this.phoneNumber) {
        if (!/^\+?\d{10,15}$/.test(this.phoneNumber)) {
          this.errors.phone = this.$t('pickupPageStrings.erPhone2');
        }
      }

      return Object.keys(this.errors).length === 0;
    },

    addSlot() {
      this.errors = {};

      if (!this.selectedDay || !this.startHour || !this.endHour) {
        if (!this.selectedDay) this.errors.day = "Le jour est requis.";
        if (!this.startHour) this.errors.startHour = "L'heure de début est requise.";
        if (!this.endHour) this.errors.endHour = "L'heure de fin est requise.";
        if (!this.typeOP) this.errors.type = "Le type est requis."
        return;
      }

      if (this.selectedType.name === this.$t("pickupPageStrings.pickupTime")){
        this.slotPickup.push({
          day: this.selectedDay.name,
          startHour: this.startHour,
          endHour: this.endHour,
          code: this.selectedDay.code
        })
      }else{
        this.slotOrder.push({
          day: this.selectedDay.name,
          startHour: this.startHour,
          endHour: this.endHour,
          code: this.selectedDay.code
        });
      }
    },

    removeSlot(index, type) {
      if (type === 'order') {
        this.slotOrder.splice(index, 1);
      } else if (type === 'pickup') {
        this.slotPickup.splice(index, 1);
      }
    },

    formatTimeInput(event) {
      let value = event.target.value.replace(/\D/g, '');

      if (value.length >= 3) {
        value = value.slice(0, 2) + '.' + value.slice(2, 4);
      }

      event.target.value = value.slice(0, 5);

      if (event.target === this.$refs.startHour) {
        this.startHour = event.target.value;
      } else if (event.target === this.$refs.endHour) {
        this.endHour = event.target.value;
      }
    },

    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },

    submitForm() {
      if (!this.validateFields()) {
        return;
      }

      const formatSlots = (slots) => {
        const formattedSlots = {};

        slots.forEach(slot => {
          const day = slot.code;

          if (!formattedSlots[day]) {
            formattedSlots[day] = [];
          }

          formattedSlots[day].push({
            start: slot.startHour,
            end: slot.endHour
          });
        });

        return formattedSlots;
      };

      const openingHoursObject = {
        order: formatSlots(this.slotOrder),
      };

      if (this.slotPickup.length > 0) {
        openingHoursObject.pickupTime = formatSlots(this.slotPickup)
      }

      if (this.editingPickup){
        const pickup = {
          pickupLocationId: this.editingPickup.pickupLocationId,
          name: this.name,
          address: this.address,
          phoneNumber: this.phoneNumber,
          orderOpeningHours: openingHoursObject
        };
        this.updatePickup(pickup)
      }else{
        const pickup = {
          name: this.name,
          address: this.address,
          phoneNumber: this.phoneNumber,
          orderOpeningHours: openingHoursObject
        };
        this.addPickup(pickup);
      }
    },

    cancel() {
      this.setEditingPickup(null);
    },
  },
  computed: {
    ...mapState('pickup', ['editingPickup', 'editPickupRequest', 'addPickupRequest', 'getPickupRequest', 'pickup']),
  },
  data() {
    return {
      name: "",
      address: "",
      phoneNumber: "",
      openingHours: "",
      day: "",
      startHour: "",
      endHour: "",
      typeOP: "",
      isMobile: 768,
      slotOrder: [],
      slotPickup: [],
      selectedDay: null,
      selectedType: null,
      days: [
        { name: this.$t("generic.monday"), code: "monday"},
        { name: this.$t("generic.tuesday"), code: "tuesday"},
        { name: this.$t("generic.wednesday"), code: "wednesday"},
        { name: this.$t("generic.thursday"), code: "thursday"},
        { name: this.$t("generic.friday"), code: "friday"},
        { name: this.$t("generic.saturday"), code: "saturday"},
        { name: this.$t("generic.sunday"), code: "sunday"},
      ],
      type: [
        { name: this.$t("pickupPageStrings.openingHours")},
        { name: this.$t("pickupPageStrings.pickupTime")},
      ],
      errors: {}
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    if (this.editingPickup) {
      this.name = this.editingPickup.name || '';
      this.address = this.editingPickup.address || '';
      this.phoneNumber = this.editingPickup.phoneNumber || '';

      if (this.editingPickup.orderOpeningHours && this.editingPickup.orderOpeningHours.order) {
        this.slotOrder = Object.keys(this.editingPickup.orderOpeningHours.order).map(day => {
          const daySlots = this.editingPickup.orderOpeningHours.order[day];
          if (daySlots) {
            return daySlots.map(slot => ({
              day: this.$t(`generic.${day}`),
              startHour: slot.start,
              endHour: slot.end,
              code: day
            }));
          }
          return [];
        }).flat();
      } else {
        this.slotOrder = [];
      }

      if (this.editingPickup.orderOpeningHours && this.editingPickup.orderOpeningHours.pickupTime) {
        this.slotPickup = Object.keys(this.editingPickup.orderOpeningHours.pickupTime).map(day => {
          const daySlots = this.editingPickup.orderOpeningHours.pickupTime[day];
          if (daySlots) {
            return daySlots.map(slot => ({
              day: this.$t(`generic.${day}`),
              startHour: slot.start,
              endHour: slot.end,
              code: day
            }));
          }
          return [];
        }).flat();
      } else {
        this.slotPickup = [];
      }
      console.log("slotOrder:", this.slotOrder);
      console.log("slotPickup:", this.slotPickup);
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: auto;
  padding: 20px 20px;
}

.slot-box{
  background-color: rgba(129, 137, 201, 0.18);
  padding: 15px;
  padding-top: 10px !important;
  border-radius: 8px;
  min-height: 260px;
  color: #efefef;
  margin-right: 5px;
  text-align: start;
  justify-content: start;
  font-size: 15px;
}

.slot-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 !important;
  margin: 0 0 5px 0 !important;
}

.input-row {
  display: flex;
  gap: 5px;
  align-items: center;
}

.time-input {
  width: 100%;
  max-width: 48%;
}

ul{
  padding: 0 !important;
  margin: 0 !important;
}
li{
  text-decoration: none;
  list-style: none
}

.customCol{
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.title{
  font-weight: 500;
}

.dropInput{
  background-color: rgba(129, 137, 201, 0.18);
  padding: 10px 40px 10px 20px;
  padding-right: 20px !important;
  height: 44px !important;
  border-radius: 8px !important;
  color: #efefef
}

.delete{
  padding: 8px !important;
  border: none;
  cursor: pointer;
  display: flex;
  border-radius: 8px !important;
  color: #DD6365 !important;
  background-color: rgba(129, 137, 201, 0.18);
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 2px;
}

select {
  padding: 10px 20px 10px 20px;
}
.button2 {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.form-input-container {
  display: flex;
  flex-direction: column;
  row-gap: $default-edge-spacing-l;
}

.button-container {
  display: flex;
  justify-content: stretch;
  align-content: stretch;
  column-gap: $default-edge-spacing-l;
  margin-top: $default-edge-spacing-xxl;
  padding-bottom: 70px;

  .button {
    width: 100%;
  }
}

.colorPicker-style {
  padding-right: 10px;
}

.input-hint-color {
  padding: 0 !important;
  font-size: 13px;
  font-weight: normal;
  color: $color-secondary-text;
  opacity: 0.8;
  margin: 0 0 8px 8px;
}

.profile-data-container {
  padding: 0 0 30px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .letter-icon {
    color: $color-primary-text;
    background-color: $color-primary-blue;
    border-radius: 150px;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    padding: 6px;
  }

  .profile-text-container {
    flex-grow: 1;
    padding-left: $default-edge-spacing-l;
    overflow: hidden;

    .title {
      font-size: $font-title;
      color: $color-primary-text;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .subtitle {
      font-size: $font-subtitle;
      color: $color-secondary-text;
    }
  }
}

.color-picker {
  height: 50px !important;
}

@media (max-width: $screen-md) {
  .button-container {
    justify-content: center;
    flex-direction: column;
    padding-top: $default-edge-spacing;

    .button {
      margin-top: $default-edge-spacing-l;

      &:nth-child(1) {
        padding-right: 0 !important;
      }
    }
  }
}
</style>