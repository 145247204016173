<template>
  <div>
    <popup-view :isVisible="pickupState.editingPickup || pickupState.isAddingPickup"
                @close="setEditingPickup(null); setIsAddingPickup(false)"
    >
      <edit-pickup-dialog/>
    </popup-view>
    <popup-view :isVisible="deleteOpenDialog" @close="deleteOpenDialog = false">
      <confirm-delete-dialog :product="currentSelect" @submitDeleteAction="submitDelete"
                             @cancelDeleteAction="deleteOpenDialog = false"/>
    </popup-view>
    <page-header
        class="header"
        @actionClick="setIsAddingPickup(true)"
        iconName="mapg.svg"
        actionIconName="plus.svg"
        :actionText="$t('pickupPageStrings.addPickup')"
        :headerTitle="$t('navBar.pickup')"
    />

    <DataTable
        :value="pickupState.pickup"
        v-model:filters="filters"
        filter-display="row"
        table-style="min-width: 50rem"
        :loading="loading"
        :global-filter-fields="['name', 'address','openingHours']"
    >
      <template #header>
        <div class="flex justify-content-end" style="margin-top: 15px">
            <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Keyword Search"/>
            </span>
        </div>
      </template>
      <template #empty> {{ $t('pickupPageStrings.notFound') }}</template>
      <template #loading> {{ $t('productGroupPage.loadingResultTable') }}</template>
      <Column field="name" header="Name" sortable></Column>
      <Column field="address" header="Address" sortable></Column>
      <Column field="phoneNumber" header="Phone Number" sortable>
        <template #body="{ data }">
          {{ data.phoneNumber ? data.phoneNumber : '' }}
        </template>
      </Column>
      <Column field="openingHours" header="Opening Hours" sortable>
        <template #body="{ data }">
          <div v-if="data.orderOpeningHours">
            <div v-for="(times, day) in data.orderOpeningHours.order" :key="day">
              <div v-if="times && times.length">
                <strong>{{ this.$t(`generic.${day}`)}} : </strong>
                <br v-if="isMobile">
                <span v-for="(time, index) in times" :key="index">
                  {{ formatTime(time.start) }} - {{ formatTime(time.end) }}<span v-if="index !== times.length - 1">, </span>
                </span>
              </div>
            </div>
          </div>
        </template>
      </Column>
      <Column field="pickupTime" header="Pickup Time" sortable>
        <template #body="{ data }">
          <div v-if="data.orderOpeningHours?.pickupTime">
            <div v-for="(times, day) in data.orderOpeningHours.pickupTime" :key="day">
              <div v-if="times && times.length">
                <strong>{{ $t(`generic.${day}`) }} : </strong>
                <br v-if="isMobile">
                <span v-for="(time, index) in times" :key="index">
              {{ formatTime(time.start) }} - {{ formatTime(time.end) }}
              <span v-if="index !== times.length - 1">, </span>
            </span>
              </div>
            </div>
          </div>
        </template>
      </Column>
      <Column field="quantity" header="Actions">
        <template #body="{data}">
          <div class="edit-row-btn">
            <button @click="setEditingPickup(data)">
              <inline-svg :src="require('@/assets/icons/edit.svg')"/>
            </button>
            <button class="delete" @click="openDeleteDialog(data)">
              <inline-svg :src="require('@/assets/icons/trash.svg')"/>
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import PageHeader from "@/views/components/PageHeader.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import {ref} from "vue";
import {FilterMatchMode} from "primevue/api";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import PopupView from './components/PopupView.vue';
import EditPickupDialog from "@/views/dialogs/EditPickupDialog.vue";
import ConfirmDeleteDialog from "@/views/dialogs/ConfirmDeleteDialog.vue";

export default {
  name: "PickupPage",
  components: {
    DataTable,
    Column,
    InputText,
    PageHeader,
    PopupView,
    EditPickupDialog,
    ConfirmDeleteDialog
  },
  data() {
    return {
      filters: ref({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        name: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        printer: {value: null, matchMode: FilterMatchMode.STARTS_WITH}
      }),
      deleteOpenDialog: false,
      currentSelect: {},
      loading: false,
      isMobile: 768,
    }
  },
  computed: {
    ...mapState({
      pickupState: "pickup"
    })
  },
  methods: {
    ...mapActions('pickup', ["getPickup", "deletePickup"]),
    ...mapMutations('pickup', ["setIsAddingPickup", "setEditingPickup"]),
    openDeleteDialog(item) {
      this.deleteOpenDialog = true;
      this.currentSelect = item;
    },
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },

    submitDelete() {
      this.deletePickup(this.currentSelect);
      this.deleteOpenDialog = false;
    },
    formatTime(time) {
      if (time.includes(':')) {
        const [hours, minutes] = time.split(':');
        return `${hours}:${minutes}`;
      } else if (time.includes('.')) {
        const [hours, minutes] = time.split('.');
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padEnd(2, '0');
        return `${formattedHours}:${formattedMinutes}`;
      } else {
        return `${time.padStart(2, '0')}:00`;
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.getPickup()
  }
}
</script>

<style lang="scss" scoped>
.edit-row-btn {
  column-gap: 10px;
  background: none !important;
}

.row-icon {
  width: 18px;
  height: 18px;

  &.disabled {
    color: $color-secondary-text !important;
  }
}
</style>