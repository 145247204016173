import { pickupService } from "@/services/pickupService";
import { toast } from "vue3-toastify";
import { i18n } from "./../lang";
import {niceNum} from "chart.js/helpers";

const state = {
    pickup: [],
    isAddingPickup: false,
    editingPickup: false,
    editPickupRequest: {
        loading: false
    },
    addPickupRequest: {
        loading: false
    },
    getPickupRequest : {
        loading: false
    },
    deletePickupRequest: {
        loading: false
    }
}

const mutations = {
    setEditingPickup(state, editingPickup){
        state.editingPickup = editingPickup;
        state.isAddingPickup = false;
    },
    setGetPickupRequest(state, {loading, message}){
        state.getPickupRequest = {
            loading: loading,
            message: message
        }
    },
    setAddPickupRequest(state, {loading, message}){
        state.addPickupRequest = {
            loading: loading,
            message: message
        }
    },
    setEditPickupRequest(state, {loading, message}){
        state.editingPickup = {
            loading: loading,
            message: message
        }
    },
    setDeletePickupRequest(state, {loading, message}){
        state.deletePickupRequest = {
            loading: loading,
            message: message
        }
    },
    updatePickup(state, newList){
        state.pickup = newList
    },
    setIsAddingPickup(state, isAddingPickup){
        state.isAddingPickup = isAddingPickup;
        state.editingPickup = null;
    }
}

const actions = {
    getPickup({commit}) {
        commit('setGetPickupRequest', {loading: true});
        pickupService.getPickup()
            .then((response) => {
                commit('setGetPickupRequest', {loading: false});
                commit('updatePickup', response.data);
                console.log("pickup : ",response.data)
            })
            .catch((error)  => commit('setGetPickupRequest', {loading: false, message: error}))
    },
    deletePickup({commit, state}, pickup) {
        pickupService.deletePickup(pickup)
            .then(() => {
                toast.success(i18n.global.t("toast.deleteSuccess"));
                const updatedPickupList = state.pickup.filter(element => element.pickupLocationId !== pickup.pickupLocationId);
                commit('updatePickup', updatedPickupList);
            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.deleteError"));
                commit('setDeletePickupRequest', {loading: false, message: error});
            });
    },
    updatePickup({commit, state}, pickup) {
        commit('setEditPickupRequest', {loading: true});
        pickupService.updatePickup(pickup)
            .then((response) => {
                toast.success(i18n.global.t("toast.successEdit"));
                commit('setEditPickupRequest', {loading: false});
                const updatedPickup = state.pickup.map(x => (x.pickupLocationId === pickup.pickupLocationId ? pickup: x));
                commit('updatePickup', updatedPickup);
                commit('setEditingPickup', null);
            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.errorEdit"));
                commit('setEditPickupRequest', {loading: false, message: error});
            })
    },
    addPickup({commit, state}, pickup){
        commit('setAddPickupRequest', {loading: true});
        console.log('vvvv', pickup)
        pickupService.addPickup(pickup)
            .then((response) => {
                toast.success(i18n.global.t("toast.createSuccess"));
                const updatedPickup = state.pickup;
                updatedPickup.push(response.data);
                commit('setAddPickupRequest', {loading: false});
                commit('updatePickup', updatedPickup);
                commit('setIsAddingPickup', false);
            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.createError"));
                commit('setAddPickupRequest', {loading: false, message: error});
            })
    }
}



export const pickup = {
    namespaced: true,
    state,
    actions,
    mutations,
}